@use '~scss/settings' as *;

.bb-social-share {
  position: relative;
  width: 100%;

  .dropdown {
    &__trigger {
      width: 100%;
    }

    &__content[class] {
      @include flex-gap(12px, column);

      position: absolute;
      left: 0 !important;
      bottom: 0 !important;
      top: unset !important;
      width: 100%;
      border: unset;
      transform: unset;
      border-radius: unset;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      z-index: 999;
      margin: unset;

      [class*='link-button'] {
        > button {
          @include flex(row, flex-start, center);
          @include font(16px, 20px, 400);
          @include size(100%, 32px);

          background-color: transparent;
          color: $color-text-primary;

          &.copied {
            border: unset;
            border-radius: unset;
          }

          svg {
            @include size(32px);
            margin: 0 8px 0 0;
          }

          &:hover {
            font-weight: 500;
          }
        }
      }

      button[class],
      a {
        &:hover {
          > span {
            font-weight: 500;
          }
        }
      }
    }
  }

  &__link-button {
    border-radius: unset;
  }

  &__button {
    @include flex(row, flex-start, center);
    @include flex-gap(8px, row);

    width: 100%;
    text-decoration: none;
    color: $color-text-primary;

    svg {
      @include size(32px);
      border-radius: 50%;
    }
  }

  &__trigger {
    &[class] {
      width: 100%;
      color: $color-text-primary;
      background-color: $color-player-card-background;
    }

    &--active {
      &[class] {
        font-weight: 600;
      }
    }
  }
}
