@use '~scss/settings' as *;

.bb-confirm-bet {
  @extend %modal-grid;

  &__container {
    grid-area: modal-main;
    overflow: auto;
    width: 100%;

    > .bb-outcome-details {
      background-color: transparent;
    }

    > .bb-outcome-details-card {
      padding: 20px 16px;
    }

    > p {
      @include font(10px, 16px, 700);

      color: $color-rang;
      text-align: center;
      text-transform: uppercase;
      margin: 0px;
      margin-top: 24px;
    }

    > hr {
      width: 100%;
      height: 1px;
      background-color: $color-alabaster;
      border: none;
      margin: 0px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    @extend %modal-edit-bet-footer;
  }

  &__teams {
    @include flex(row, center, center);

    width: 100%;
    padding: 12px 8px;
    background-color: $color-player-card-background;

    &__details {
      @include flex(column, flex-end, center);
      @include size(100%);

      width: 30%;
      padding-top: 12px;

      &--info,
      &--date {
        @include flex(column, center, center);
        @include font(10px, 16px, 700);

        margin: 0 0 8px;
        color: $color-text-secondary;
        letter-spacing: 0.5px;
      }

      &--vs {
        @include font(16px, 20px, 600);

        margin: 0 0 12px;
        color: $color-text-primary;
      }
    }

    > .bb-bet-team {
      &--active {
        > img {
          border: none;
        }
      }
      > img {
        @include size(64px);
      }
    }
  }

  &__bet {
    @include flex(row, space-between, flex-start);

    width: 100%;
    padding: 16px;

    > p {
      @include font(10px, 16px, 700);

      margin: 0px;
      color: $color-text-secondary;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    > p:nth-child(2) {
      @include font(24px, 28px, 600);

      color: $color-text-primary;
    }
  }
}
