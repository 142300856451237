@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-flex' as *;
@use './tools//t-border-radius' as *;

@mixin flag($background) {
  @include size(10px, 8px);

  box-sizing: content-box;
  padding-top: 8px;
  position: relative;
  background: $background;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 5px solid $color-cards-background;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}

@mixin rect-flag {
  position: relative;
  width: 0;
  height: 0;
  border-top: 16px solid $color-win-flag;
  border-right: 8px solid transparent;

  &:after {
    content: '';
    position: absolute;
    left: 1px;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 16px solid $color-error;
    border-left: 8px solid transparent;
  }
}

%user-flag-data {
  @include flex(column, center, flex-start);

  width: 100%;
  position: relative;

  > p {
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: $color-text-primary;
  }

  > a {
    position: absolute;
    right: 0;
    min-width: 0px;
    background-color: transparent;
    color: $color-text-secondary;

    > button {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  > div:nth-child(2) {
    @include flex(row, flex-start, center);

    width: 100%;

    > p {
      margin: 0px;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: $color-text-flags;
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 16px;
    }

    > svg:nth-child(1) {
      color: $color-win-flag;
      margin-right: 8px;
    }

    > svg:nth-child(3) {
      color: $color-error;
      margin-right: 8px;
    }
  }
}
