@use '~scss/settings' as *;

.bb-chat-modal {
  top: $modal-top;

  &__message-indicator {
    .bb-image--active {
      @include absolute(bottom 18px right 0);
    }
  }

  &__message-date {
    @include flex(row, center, center);

    width: 100%;
    padding: 12px 0px;

    > p {
      @include font(10px, 16px, 600);

      flex: 0 0 1;
      text-align: center;
      background-color: $color-alabaster;
      color: $color-disabled;
      border-radius: $border-radius-round;
      padding: 2px 8px;
      margin: 0px;
    }
  }

  &__container {
    @include flex(column-reverse);

    width: 100%;
    overflow: auto;
    margin-top: 8px;
    padding: 0px 8px;
    grid-area: modal-main;

    &::-webkit-scrollbar {
      display: none;
    }

    > p:first-child {
      @include absolute(top 60px left 50%);

      color: $color-text-secondary;
    }
  }

  &__footer-without-margin {
    @include flex(row);

    width: 100%;
    padding: 8px;
    border-top: 1px solid $color-alabaster;

    .ncoded-input {
      @extend %input-rounded;
      @include border-right-radius(0px);
      @include size(100%, 36px);

      border: 0px;
      padding: 0px 8px;
      color: $color-text-secondary;
      background-color: $color-search;

      &:focus-within {
        border: 0px;
      }

      > svg {
        fill: $color-text-secondary;
      }

      > input::placeholder {
        color: $color-disabled;
      }
    }

    .ncoded-button:first-of-type {
      @include size(36px);
      @extend %primary-button;

      min-width: 36px;
      margin-right: 8px;
      background-color: $color-active;
      border-radius: $border-radius-round;
      color: $color-white;

      > svg {
        @include size(24px);
      }
    }

    .ncoded-button:nth-child(3) {
      @include size(36px);
      @include border-left-radius(0px);
      @include border-right-radius(24px);
      @extend %primary-button;

      min-width: 36px;
      margin-right: 8px;
      background-color: $color-search;

      > svg {
        @include size(24px);
      }
    }
  }

  &__message-container {
    @include flex(row, center, flex-end);
    @include flex-gap(8px, row);

    margin-bottom: 18px;

    &__message {
      @include font(14px, 16px, 500);

      color: $color-text-primary;
      width: auto;
      text-align: right;
      letter-spacing: 0px;
      text-align: left;
      padding: 6px 12px;
      word-break: break-word;
      border-radius: $border-radius-round;
    }

    &--recieved {
      justify-content: flex-start;

      .bb-betchat-card {
        background-color: $color-disabled;
        border-bottom-left-radius: 0px;
      }

      .bb-chat-modal__message-container {
        &__message {
          background-color: $color-dutch-white;
          border-bottom-left-radius: 0px;
          text-align: left;
        }

        &__contnet {
          > p {
            text-align: left;
          }

          &--full-width {
            width: 100%;
            padding-left: 30%;

            > p {
              text-align: left;
            }
          }
        }
      }
    }

    &--sent {
      justify-content: flex-end;

      .bb-betchat-card {
        background-color: $color-unique-button;
        border-bottom-right-radius: 0px;
      }

      .bb-chat-modal__message-container {
        &__message {
          background-color: $color-unique-button;
          border-bottom-right-radius: 0px;
          text-align: right;
        }

        &__content {
          > p {
            text-align: right;
          }

          &--full-width {
            width: 100%;
            padding-left: 30%;

            > p {
              text-align: right;
            }
          }
        }
      }
    }

    p {
      @include font(10px, 16px, 600);

      margin: 0px;
      margin-top: 4px;
      color: $color-rang;
    }

    > .bb-image {
      @include flex(row, center, flex-end);

      height: 100%;
      margin-right: 8px;
      padding: 0px 0px 20px;

      &--active {
        bottom: 18px;
      }

      > img {
        @include size(32px);
      }
    }

    .bb-betchat-card {
      padding: 4px;
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      @include flex(row, flex-start, center);
      @include flex-gap(12px, row);

      height: auto;
      padding: 16px 20px;
      border-bottom: 1px solid $color-alabaster;

      > img {
        @include size(36px);

        object-fit: cover;
        margin-left: 12px;
        border-radius: 50%;
      }

      > div:nth-child(2) {
        @include flex(column, center, flex-start);

        width: 100%;

        > p:nth-child(1) {
          @include font(14px, 16px, 600);

          margin: 0px;
          color: $color-text-primary;
          margin-bottom: 4px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > p:nth-child(2) {
          @include font(10px, 16px, 600);

          margin: 0px;
          color: $color-active;
        }
      }
    }

    > main {
      @extend %modal-grid;

      position: relative;
      height: calc(100% - 149px);

      > form {
        .bb-input-field {
          margin-bottom: 16px;
        }

        .ncoded-button--link {
          color: $color-text-secondary;
        }

        > button {
          width: 100%;
          // margin-bottom: 6px;
        }
      }

      > div:first-of-type {
        @include absolute(top 0 right 0);

        width: 100%;
        background-color: $color-cards-background;

        > p {
          @include font(12px, 16px, 500);

          margin: 4px 0px;
          text-align: center;
          letter-spacing: 0.5px;
          color: $color-text-secondary;
        }

        > .loader-container {
          position: relative;
          height: auto;
          padding: 4px;

          &--overlay {
            background-color: $color-cards-background;
            opacity: 0.6;
          }
        }
      }

      > p:first-of-type {
        @include font(12px, 16px, 500);

        text-align: center;
        letter-spacing: 0.5px;
        color: $color-text-secondary;
      }
    }
  }
}
