@use '~scss/settings' as *;

.bb-newchat-modal {
  top: $modal-top;

  &__container {
    justify-content: right;
    overflow: auto;
    width: 100%;
    margin-top: 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    > .ncoded-input {
      @extend %input-rounded;

      color: $color-text-secondary;
      height: 36px;
      padding: 0px 8px;
      border: 0px;
      background-color: $color-search;
      margin: 12px 12px 8px;

      &:focus-within {
        border: 0px;
      }

      > svg {
        @include size(24px);

        fill: $color-text-primary;
      }

      > input::placeholder {
        color: $color-disabled;
      }
    }
  }

  &__footer {
    @extend %modal-footer;
  }

  &__friend {
    @include flex(row, flex-start, center);
    @include size(100%, 64px);

    padding: 12px;
    padding: unset;
    cursor: pointer;
    color: $color-text-primary;

    &--active {
      background-color: $color-cards-background;

      > p {
        color: $color-text-primary;
      }
    }

    > img {
      @include size(40px);

      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    > p {
      @include font(14px, 20px, 700);

      margin: 0px;
      margin-left: 12px;
      letter-spacing: 0.5px;
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      > p {
        @include font(12px, 16px, 600);

        color: $color-text-primary;
        text-transform: uppercase;
      }
    }

    > main {
      height: 100%;

      > form {
        @extend %modal-grid;

        .bb-input-field {
          margin-bottom: 16px;
        }

        .ncoded-button--link {
          color: $color-text-secondary;
        }

        > button {
          width: 100%;
          margin-bottom: 6px;
        }
      }

      > p {
        @include font(12px, 16px, 600);

        text-align: center;
        color: $color-error;
        text-transform: capitalize;
      }
    }
  }
}
