.svg-styles {
  &__right {
    transform: rotate(-90deg);
  }
  &__top {
    transform: rotate(180deg);
  }
  &__left {
    transform: rotate(90deg);
  }
}
