@use '~scss/settings' as *;

.bb-main {
  @include size(100%);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'content'
    'footer';

  font-weight: 600;
  background-color: $color-background-primary;

  &__header {
    @include flex(row, space-between, center);

    grid-area: header;
    width: 100%;
    padding: 10px 12px;
    z-index: 1;
    background-color: $color-header;
    position: relative;

    &::before {
      @include size(32px);
      @include absolute(bottom 0 left 0);

      content: '';
      display: block;
      transform: translate3d(0, 100%, 0);

      border-radius: 16px;
      box-shadow: -16px -16px 0 0 $color-header;
      -webkit-box-shadow: -16px -16px 0 0 $color-header;
    }

    &::after {
      @include size(32px);
      @include absolute(bottom 0 right 0);

      content: '';
      display: block;
      transform: translate3d(0, 100%, 0);

      border-radius: 16px;
      box-shadow: 16px -16px 0 0 $color-header;
      -webkit-box-shadow: 16px -16px 0 0 $color-header;
    }

    > .ncoded-button {
      @include font(12px, 14px, 500);

      min-width: 0px;
      border-radius: 18px;
      padding: 10px 12px;
      letter-spacing: 0.5px;

      > svg {
        transform: translateY(-1px);
      }

      &:first-child {
        color: $color-white;
        background-color: transparent;
        border: none;

        > svg {
          margin: 0px;
          margin-right: 6px;
        }
      }

      &:last-child {
        @include size(36px);

        > svg {
          @include size(12px);

          color: $color-text-primary;
          margin: 0px;
        }
      }
    }
  }

  &__content {
    grid-area: content;
    scroll-behavior: smooth;
    overflow: auto;
  }

  &__footer {
    grid-area: footer;
    z-index: 2;
  }
}
