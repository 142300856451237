@use '~scss/settings' as *;

.bb-wallet-option {
  .bb-collapsible__trigger {
    width: 100%;
  }

  &__trigger {
    @extend %standard-setting;

    width: 100%;

    &--open {
      > svg {
        transform: rotate(270deg);
      }
    }
  }

  &__wallet-content {
    @include flex-gap(8px, column);

    width: 100%;
    padding: 8px 0;
  }
}
