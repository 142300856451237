@use '~scss/settings' as *;

.bb-odds {
  @extend %modal-grid;

  .bb-input-field {
    margin: 0px;
  }

  &__container {
    @include flex(column, flex-start, center);

    width: 100%;
    padding: 0px 16px;
    margin-top: 16px;

    &__label {
      @include font(10px, 16px, 700);

      color: $color-rang;
      text-transform: uppercase;
      margin: 0 0 12px;
      letter-spacing: 0.5px;
    }
  }

  &__range-slider {
    margin: 0 0 24px;
  }

  &__value[class] {
    margin-bottom: 0px;
    margin: 0px;

    small {
      @include flex(row, center);
      width: 100%;
    }

    .ncoded-input {
      max-width: 40%;
      margin: 0 auto;
      margin-bottom: 20px;
      border: 1px solid $color-rang;
    }

    input {
      @include font(24px, 32px, 700);

      border: unset;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &__maximum {
    @include font(18px, 20px, 500);

    color: $color-text-primary;
    text-align: center;
    margin: 0px;
    width: 100%;
  }

  &__help {
    width: 100%;
    margin: auto 0 24px 0;

    &__q {
      @include font(16px, 20px, 600);
      color: $color-text-primary;
      margin: 0 0 8px;
    }

    &__a {
      @include font(14px, 16px, 500);
      color: $color-text-primary;
      margin: 0px;
    }
  }

  &__footer {
    @extend %modal-edit-bet-footer;
  }
}
