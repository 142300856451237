@use '~scss/settings' as *;

.bb-chat-card {
  display: grid;
  grid-template-columns: 48px 1fr;
  width: 100%;
  padding: 12px;
  border-top: 1px solid $color-alabaster;

  &--is-viewed {
    background-color: $color-alabaster;
  }

  &__content {
    @include flex(row, flex-start, center);

    width: 100%;
    padding-left: 12px;
    overflow: hidden;

    > div:nth-child(1) {
      width: 80%;

      > p:nth-child(1) {
        @include font(14px, 20px, 700);

        margin: 0px;
        color: $color-text-primary;
        letter-spacing: 0.5px;
      }

      > p:nth-child(2) {
        @include font(12px, 16px, 600);

        margin: 0px;
        color: $color-text-secondary;
        letter-spacing: 0.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60%;
      }
    }

    > div:nth-child(2) {
      @include flex(column, center, flex-end);
      @include size(20%, 100%);

      padding: 0px 8px;

      > p {
        @include font(10px, 16px, 600);

        margin: 0px;
        color: $color-text-secondary;
        letter-spacing: 0.5px;
      }
    }
  }
}
