@use '~scss/settings' as *;

.bb-calendar {
  @include flex-gap(20px, column);

  background-color: $color-player-card-background;
  padding: 30px;

  > main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    > section:last-child {
      padding-right: 0;
    }

    > section:first-child {
      padding-left: 0;
    }

    > section {
      > p {
        @include font(16px, 20px, 600);

        margin: 0 0 15px;
        color: var(--color-text-primary);
      }
    }
  }
}
