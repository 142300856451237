@use '~scss/settings' as *;

.datepicker-menu-container {
  @include size(100%);
  user-select: none;

  &__header {
    @include flex(column, center, center);

    > :first-child {
      border-bottom: 1px solid $color-alabaster;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(7, 40px);
    width: 100%;
    place-content: center;

    &__field {
      @include flex(row, center, center);
      @include font(12px, 16px, 600);

      padding-top: 100%;
      position: relative;
      border-radius: 50%;
      color: $color-rang;
      transition: background 100ms ease-in-out;

      &__content {
        @include size(fit-content);

        position: absolute;
        inset: 50% 50%;
        transform: translate(-50%, -50%);
      }

      &--current-date {
        position: relative;

        &::before {
          @include size(4px);
          @include absolute(top 0 left 50%);

          content: '';
          border-radius: 50%;
          background-color: $color-active;
          transform: translate3d(-50%, 0, 0);
        }
      }

      &--valid {
        cursor: pointer;
        color: $color-text-primary;
      }

      &--different-month {
        color: $color-rang;
      }

      &--invalid {
        color: $color-rang;
        cursor: default;
        text-decoration: line-through;
      }

      &--selected {
        color: $color-text-primary;
        background-color: $color-active;
        box-shadow: $neon-box-shadow;
      }
    }
  }
}
