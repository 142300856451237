@use '~scss/settings' as *;

.bb-feed-filter-modal {
  @include flex(row, center, flex-end);

  &__container {
    overflow: auto;
    width: 100%;
    padding: 16px 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__title {
      @include font(10px, 16px, 700);

      margin-bottom: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $color-text-secondary;
    }

    .bb-radio-slider:nth-child(2) {
      @extend %slider-primary;
      @extend %slider--sports-list;
    }

    .bb-radio-slider {
      &:nth-child(4),
      &:nth-child(6) {
        @extend %slider-rect;
        > label {
          @include font(12px, 36px, 700);
        }
      }
    }
  }

  &__footer {
    @include flex(row, center, center);
    @include size(100%, 68px);

    padding: 12px;
    border-top: 1px solid $color-alabaster;

    > .ncoded-button {
      width: 100%;

      &:first-of-type {
        margin-right: 12px;
      }

      &--secondary {
        color: $color-text-primary;
        background-color: $color-not-active-button;
      }
    }
  }

  .overlay__content {
    @extend %filter-modal;
  }
}
