@use '~scss/settings' as *;

.bb-outcome-money {
  @include flex(column, center, center);

  flex: 1;
  gap: 2px;
  padding: 12px 16px 8px;
  border-radius: $border-radius-standard;
  line-height: 16px;
  text-transform: uppercase;

  &__amount {
    font-size: 20px;
    color: $color-text-primary;
  }

  &__text {
    font-size: 10px;
    color: $color-text-secondary;
  }

  &--outline {
    background-color: transparent;
    border: 1px solid $color-alabaster;
  }

  &--solid {
    background-color: $color-player-card-background;
    border: 1px solid $color-player-card-background;
  }
}
