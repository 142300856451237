@use '~scss/settings' as *;

.bb-menu {
  @include flex(column, flex-start, center);
  @include border-top-radius($border-radius-standard);

  overflow: auto;
  padding: 0px 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__profile {
    @include border-top-radius($border-radius-standard);

    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    background-color: $color-alabaster;
    padding: 16px;
    margin-top: 12px;

    &__data {
      @extend %user-flag-data;
    }

    > .ncoded-button {
      @include flex(row, center, center);
      @include size(64px);

      background-color: transparent;
      min-width: 0px;
      border-radius: 50%;
      margin-right: 12px;

      > label {
        @include size(100%);

        > .bb-user-image {
          @include size(100%);

          > img {
            @include size(100%);

            object-fit: contain;
            border-radius: 50%;
            margin: 0px;
          }
        }
      }
    }
  }

  &__item {
    @include flex(row, flex-start, center);

    width: 100%;
    min-height: 60px;
    padding: 16px;
    color: $color-text-secondary;
    border-bottom: 1px solid $color-alabaster;
    border-right: 1px solid $color-alabaster;
    border-left: 1px solid $color-alabaster;
    text-decoration: none;
    position: relative;

    > svg {
      @include size(24px);

      margin-right: 12px;

      &:nth-last-of-type(1) {
        @include absolute(right 12px);

        height: 100%;
        min-width: 0px;
        margin-right: 4px;
        color: $color-text-secondary;
        background-color: transparent;
      }
    }

    > p {
      @include font(12px, 16px, 700);

      margin: 0px;
      color: $color-text-primary;
      letter-spacing: 0.5px;
    }

    > div:last-child {
      @include flex(row, center, center);
      @include absolute(right 15%);
      @include font(12px, 16px, 700);

      padding: 2px 8px;
      border-radius: 10px;
      color: $color-white;
      background-color: var(--color-button-primary);
    }
  }

  &__logout {
    @include flex(row, flex-start, center);

    width: 100%;
    color: $color-text-primary;
    padding: 18px;
    margin-top: 24px;
    border: 2px solid $color-alabaster;

    > p {
      @include font(12px, 16px, 700);

      text-transform: uppercase;
      margin-left: 12px;
    }

    > svg {
      color: $color-text-secondary;
    }
  }
}
