@use '~scss/settings' as *;

.bb-coin-address-card {
  @include flex(row, space-between, center);

  width: 100%;
  min-height: 56px;
  border-radius: $border-radius-standard;
  border: 2px solid $color-player-card-background;

  &--disabled {
    pointer-events: none;
    .bb-coin-address-card__content {
      opacity: 0.5;
    }
  }

  &__content {
    width: 100%;
    padding: 8px 10px;

    > p {
      margin: 0px;
      overflow: hidden;
      max-width: 200px;
      white-space: nowrap;
      word-break: break-word;
      text-overflow: ellipsis;
    }

    &--address {
      @include font(14px, 24px, 600);

      color: $color-text-primary;
    }

    &--coin-type {
      @include font(12px, 16px, 600);

      color: $color-text-primary;
      letter-spacing: 0.5px;
    }
  }

  &__actions {
    @include flex(row, center, center);

    margin: 0 16px 0 0;

    > p {
      @include font(8px, 12px, 700);

      margin: 0px;
      padding: 4px 8px;
      border-radius: 4px;
      color: $color-radio-slider;
      text-transform: uppercase;
      background-color: $color-yellowish;
    }

    > img {
      @include size(40px);

      border-radius: $border-radius-standard;
    }

    > .ncoded-button {
      @extend %secondary-button;
      @include size(32px);

      padding: 2px;
      text-transform: uppercase;
      background-color: $color-player-card-background;

      > svg {
        @include size(100%);

        color: $color-text-secondary;
      }
    }
  }
}
