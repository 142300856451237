@use '~scss/settings' as *;

.bb-fixture {
  @include flex(column, center, center);

  width: 100%;
  cursor: pointer;
  border-top: 1px solid $color-alabaster;
  color: $color-text-primary;
  background-color: $color-background-primary;

  &--active {
    background-color: $color-alabaster;
  }

  &__game-type {
    @include flex(row, space-between, center);

    width: 100%;
    padding: 12px;

    > label {
      @include font(10px, 16px, 600);

      color: $color-text-secondary;
      text-transform: uppercase;
    }
  }

  &__game-details {
    @include flex(row, space-between, center);

    width: 100%;
    padding: 8px 12px 20px;

    &__team {
      @include flex(column, flex-start, center);
      @include size(100%);

      gap: 10px;
      flex: 1;
      align-self: flex-start;

      &--minimize-logo {
        > img {
          transform: scale(1.6);
        }
      }

      > img {
        @include size(48px);

        object-fit: contain;
      }

      > label {
        @include font(14px, 16px, 600);

        text-align: center;
      }
    }

    &__details {
      @include flex(column, center, center);
      @include size(100%);

      flex: 1;
      align-self: flex-start;
      padding: 20px 0px;

      > p {
        @include font(10px, 16px, 600);

        color: $color-text-secondary;
        text-transform: uppercase;
        margin: 0px;
      }

      > p:first-child {
        @include font(20px, 16px, 600);

        color: $color-text-primary;
      }
    }
  }
}
