@use '~scss/settings' as *;

.bb-withdraw {
  @extend %modal-grid;

  width: 100%;

  &__content {
    grid-area: modal-main;
    overflow: auto;
    width: 100%;
    padding: 22px 16px;
    background-color: $color-background-primary;

    > hr {
      width: 100%;
      height: 1px;
      background-color: $color-alabaster;
      border: none;
      margin: 16px 0px;
    }

    > .bb-input-field {
      width: 100%;
      margin: 0px;

      > label {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }

      .ncoded-input {
        min-height: 56px;
        border: 1px solid $color-alabaster;

        > input {
          text-align: center;
        }
      }
    }

    > p {
      @include font(16px, 24px, 700);

      color: $color-text-primary;
      margin: 0px;
      margin-bottom: 16px;
    }
  }

  &__footer {
    @extend %modal-footer;

    padding-bottom: 30px;
  }

  .bb-card {
    &--active {
      background-color: $color-background-secondary;
    }

    > svg {
      color: $color-active;
    }
  }
}
