@use '~scss/settings' as *;

.bb-game-details {
  @include flex(row, space-between, flex-start);

  width: 100%;
  padding: 12px;
  font-size: 10px;

  &__sport {
    flex: 1;
    line-height: 16px;
    color: $color-text-secondary;
  }

  &__time {
    @include flex(column, center, center);

    flex: 1;
    text-align: center;

    &__hour {
      font-size: 20px;
      color: $color-text-primary;
    }

    &__date {
      line-height: 16px;
      color: $color-text-secondary;
    }
  }

  &__league {
    flex: 1;
    line-height: 16px;
    text-align: end;
    color: $color-text-secondary;
  }
}
