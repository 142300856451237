@use '~scss/settings' as *;

.bb-bet-team {
  @include flex(column, flex-start, center);

  width: 40%;
  padding: 12px 8px;
  position: relative;
  border: 1px solid $color-player-card-background;
  background-color: $color-player-card-background;
  border-radius: 8px;

  &--active {
    background-color: $color-background-secondary;

    > img {
      box-shadow: $neon-box-shadow;
      border: 1px solid $color-active;
      opacity: 1;
      object-fit: cover;
    }
  }

  > img {
    @include size(100%);

    object-fit: contain;
    margin-bottom: 12px;
    border-radius: 50%;
  }

  .bb-bet-team__name,
  .bb-bet-team__score {
    @include font(14px, 16px, 600);

    text-align: center;
    word-break: break-word;
    color: $color-text-primary;
  }

  .bb-bet-team__score {
    font-size: 12px;
  }

  > svg {
    @include size(12px, 18px);
    @include absolute(top 0 right 8px);

    color: $color-win-flag;
  }

  &--betting-odd-active {
    border: 1px solid $color-active;
    background-color: transparent;
  }

  &__betting-odd {
    @include font(12px, 16px, 600);

    min-width: 36px;
    position: absolute;
    top: 8px;
    padding: 3px 6px 3px 6px;
    border-radius: 4px;
    border: 1px solid $color-text-secondary;

    &--active {
      border: 1px solid $color-active;
    }
  }
}
