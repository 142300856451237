@use '~scss/settings' as *;

.bb-notification-card {
  @include flex(row, flex-start, center);
  @include flex-gap(12px, row);

  padding: 12px;
  position: relative;
  text-decoration: none;

  &__user {
    @include flex(row, center, center);
    @include size(40px);

    position: relative;

    &__flag > svg {
      @include size(20px);
      @include absolute(top 24px left 24px);

      border-radius: 50%;
    }
  }

  &__content {
    @include flex(column, center, flex-start);

    width: 100%;
  }

  &__user-info {
    margin: 0px;
    width: 100%;
    position: relative;
    padding-right: 40px;
    word-break: break-word;

    > span {
      @include font(12px, 20px, 500);

      color: $color-text-secondary;
      white-space: pre;
      padding-right: 10px;

      &:first-child {
        @include font(14px, 18px, 700);

        color: $color-text-primary;
      }

      &:last-of-type {
        @include absolute(top 0 right -10px);
      }
    }
  }

  &__notification-message {
    @include flex(row, space-between, center);

    width: 100%;

    &__text {
      @include font(14px, 20px, 500);

      position: relative;
      margin: 0px;
      padding-right: 30px;
      word-break: break-word;
      color: $color-text-primary;
    }
  }

  &--active {
    background-color: $color-cards-background;

    .active-dot {
      @include size(8px);
      @include absolute(right 20px);

      border-radius: 50%;
      background-color: $color-active;
    }
  }
}
