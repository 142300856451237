@use '~scss/settings' as *;

.bb-glow-scroll {
  position: relative;

  &::before {
    @include absolute(0);
    @include flex(row);
    @include size(100%);

    content: '';
    box-shadow: 0px 0px 60px $color-active;
    transition: opacity 300ms ease-in-out;
  }

  &--active {
    &::before {
      opacity: 1;
    }
  }

  &--inactive {
    &::before {
      opacity: 0;
    }
  }
}
