@use '~scss/settings' as *;

.bb-fixtures {
  @include size(100%);

  display: grid;
  grid-template-rows: 56px 1fr;
  grid-gap: 8px;
  background-color: $color-background-primary;

  &::-webkit-scrollbar {
    display: none;
  }

  .overlay__content {
    > main {
      background-color: $color-background-primary;
    }
  }

  &__header {
    @include flex(row, space-between, center);
    @include border-bottom-radius(8px);

    width: 100%;
    padding: 20px 16px;
    background-color: $color-cards-background;

    > label {
      text-transform: uppercase;
      font-size: 12px;
      color: $color-text-secondary;
      font-weight: 700;
    }
  }

  &__content {
    overflow: auto;
    width: 100%;
    height: calc(100% - 112px);

    .bb-fixture {
      &__details {
        > label {
          font-size: 8px;
          line-height: 12px;
          letter-spacing: 0.5px;
          font-weight: 600;
        }
      }
    }

    > * {
      margin-bottom: 8px;
    }
  }
}
