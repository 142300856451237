@use '~scss/settings' as *;

.bb-coin-confirm-popup {
  background: $color-not-active-button;
  animation: none;

  &__data {
    @include flex(row, center, center);

    width: 100%;
    margin-top: 12px;
    padding: 8px 12px;
    border-radius: $border-radius-standard;
    color: $color-text-primary;
    background-color: $color-player-card-background;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-align: center;
  }

  &__container {
    @include flex(column, center, center);
    @include size(100%);

    margin-bottom: 8px;

    > p {
      font-size: 12px;
    }

    > .bb-input-field {
      width: 100%;

      > label {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }

      > .ncoded-input {
        min-height: 56px;

        > input {
          text-align: center;
        }
      }
    }
  }

  &__form-actions {
    @include footer-modal;

    .ncoded-button:first-child {
      background-color: $color-not-active-button;
      border: none;
    }
  }

  > .overlay__content {
    @include confirm-modal;

    > main {
      > form {
        @include flex(column, flex-start, flex-start);
        @include size(100%);
      }
    }
  }
}
