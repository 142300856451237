@use '~scss/settings' as *;

.bb-catalog {
  @include size(100%);
}

.test {
  @include flex(row, center, center);
  @include size(100%);

  background-color: $color-radio-slider;
}
