@use '~scss/settings' as *;
@use '../../scss/tools/t-border-radius';

.bb-auth {
  @include flex(column, flex-start, center);
  @include font(24px, 32px, 600);
  @include size(100%);

  background-color: $color-background-primary;
  padding: 10px 0 0;

  &::-webkit-scrollbar {
    display: none;
  }

  &__content {
    @include flex(column, flex-start, center);
    @include size(100%);

    position: relative;

    &__bg {
      @include flex(column, flex-end, center);
      @include size(100%);

      padding-bottom: 160px;

      > img {
        @include size(264px, 125px);
      }

      > svg {
        @include flex(row, center, center);

        top: 35%;
        z-index: 1;
      }

      > p {
        @include flex(row, center, center);

        position: relative;
        margin: 0px;
        font-size: 16px;
        color: $color-text-primary;
        text-transform: uppercase;
      }

      &__logo {
        @include flex(column, center, center);
        @include size(100%, 'fite-content');

        .sign-logo {
          @include size(80px);

          margin: 0 0 16px;
        }

        .text-logo {
          margin: 0 0 8px;
          min-height: 40px;
        }
      }

      .text-message {
        @include font(12px, 16px, 700);

        position: relative;
        min-height: 40px;
      }
    }

    &__actions {
      @include flex(column, center, center);
      @include size(100%, 'fit-content');

      position: absolute;
      padding: 16px;
      bottom: 0;

      > * {
        margin-bottom: 16px;
      }

      > .ncoded-button {
        width: 100%;
        height: $button-height;
        font-weight: 700;

        &--secondary {
          color: $color-button-primary;
          background-color: $color-white;
        }
      }
    }

    > img {
      @include size(100%, 80%);
      @include border-top-radius($border-radius-standard);

      position: absolute;
      object-fit: contain;
    }
  }

  &__toggle {
    @include flex(row, space-between, center);
    @include size(100%);

    color: $color-text-secondary;
    margin: 22px 0px;

    > p {
      font-weight: 600;
      margin: 0px;
      line-height: 20px;
      letter-spacing: 0.5px;
      font-size: 14px;
    }
  }

  > svg:first-of-type {
    margin-bottom: 10px;
  }

  > p {
    text-align: center;
  }

  .betbears-logo {
    @include flex(row, center, center);
    @include size(100%, 5%);

    margin-top: 10px;

    > img {
      @include size(36px);
      position: absolute;
    }

    > img:nth-child(2) {
      @include size(15px);
    }
  }
}
