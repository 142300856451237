@use '~scss/settings' as *;

.bb-outcomes-form {
  @extend %modal-grid;

  > small {
    color: $color-error;
    font-size: 14px;
    text-align: center;
    width: 100%;
  }

  &__container {
    grid-area: modal-main;
    overflow: auto;
    width: 100%;
    margin-top: 14px;
    padding: 0px 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    @extend %modal-edit-bet-footer;
  }

  &__content {
    @include size(100%);
  }

  .bb-radio-slider {
    @include flex(row, center, flex-start);

    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: auto;
    margin-bottom: 16px;

    &::before {
      height: 100%;
      z-index: 1;
      border-radius: $border-radius-standard;
      left: 0;
      box-shadow: none;
    }

    > label {
      @include flex(row, center, center);
      @include font(12px, 16px, 700);
      @include size(33.3%, 100%);

      flex: 1;
      min-height: 100%;
      text-align: center;
      white-space: unset;
      overflow-wrap: break-word;
      border-radius: $border-radius-standard;
    }

    > label {
      z-index: 1;
    }
  }

  > button {
    width: 100%;
  }
}
