@use '~scss/settings' as *;

.bb-share-modal {
  top: $modal-top;

  &__container {
    @include flex(column, flex-start, center);

    overflow: auto;

    &__card {
      @include flex(row, space-between, center);

      width: 100%;
      padding: 12px;

      > div {
        @include flex(row, flex-start, center);

        width: 100%;
        overflow: hidden;

        > p {
          @include font(14px, 20px, 600);

          color: $color-text-primary;
          margin: 0px;
          letter-spacing: 0.5px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .bb-user-image {
          margin-right: 12px;

          > img {
            @include size(40px);

            object-fit: contain;
          }
        }
      }

      > button {
        @include font(10px, 16px, 600);

        height: 28px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  }

  .ncoded-input {
    @extend %input-rounded;

    height: 36px;
    padding: 0 8px;
    border: 0px;
    background-color: $color-background-secondary;

    &:focus-within {
      border: 0px;
    }

    > svg {
      fill: $color-text-secondary;
    }

    > input::placeholder {
      color: $color-disabled;
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      > p {
        @include font(12px, 16px, 600);

        color: $color-text-primary;
        text-transform: uppercase;
      }
    }

    > main {
      height: calc(100% - 112px);
      width: 100%;

      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 1fr;
      grid-gap: 8px;
      overflow: auto;
      padding: 12px 8px;

      > form {
        .bb-input-field {
          margin-bottom: 16px;
        }

        .ncoded-button--link {
          color: $color-text-secondary;
        }

        > button {
          width: 100%;
          margin-bottom: 6px;
        }
      }

      > p {
        @include font(12px, 16px, 600);

        text-align: center;
        color: $color-error;
        text-transform: capitalize;
      }
    }
  }
}
