@use '~scss/settings' as *;

.bb-bet-confirm-popup {
  background: $color-not-active-button;
  animation: none;

  &__data {
    @include flex(row, center, center);
    @include font(12px, 16px, 600);
    @include size(100%, auto);

    margin-top: 12px;
    padding: 8px 12px;
    border-radius: $border-radius-standard;
    color: $color-active-secondary;
    background-color: $color-player-card-background;
    letter-spacing: 0.5px;
    text-align: center;
  }

  &__form-actions {
    @include footer-modal;

    margin-top: auto;
    padding: 0px;

    .ncoded-button:first-child {
      background-color: $color-text-secondary;
      color: $color-text-primary;
      border: none;
      opacity: 0.3;
    }
  }

  &__outcome-message {
    @include flex(row);
    @include font(12px, 20px, 600);

    margin: 12px 4px;
    color: $color-text-primary;

    > svg {
      @include size(20px);

      color: $color-yellowish;
      margin-right: 4px;
    }
  }

  > .overlay__content {
    @include confirm-modal;

    > main {
      > form {
        @include flex(column, flex-start, flex-start);
        @include size(100%);

        position: relative;

        > p {
          font-size: 12px;
        }

        > .bb-input-field {
          width: 100%;

          > label {
            left: 50%;
            transform: translate3d(-50%, 0, 0);
          }

          > .ncoded-input {
            min-height: 56px;

            > input {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
