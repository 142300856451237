@use '~scss/settings' as *;

.bb-team-card {
  @include flex(row, center, center);

  width: 100%;
  border-top: 1px solid $color-alabaster;
  padding: 14px 8px;

  > img {
    @include size(48px);

    border-radius: 50%;
    margin-right: 16px;
  }

  &__content {
    @include flex(column, flex-start, flex-start);

    width: 100%;
    padding: 8px 0px;

    &__data {
      > p {
        @include font(10px, 16px, 600);

        margin: 0px;
        color: $color-text-flags;
        letter-spacing: 0.5px;
        margin-right: 12px;
      }

      > p:last-child {
        margin-right: 0px;
      }
    }

    > p:first-of-type {
      @include font(14px, 20px, 600);

      color: $color-text-primary;
      letter-spacing: 0.5px;
      margin: 0px;
      margin-bottom: 12px;
    }

    > div:first-of-type {
      @include flex(row);
      flex-wrap: wrap;
    }
  }

  &__sport {
    @include flex(row, center, center);
    width: 10%;

    > svg {
      color: $color-text-secondary;
    }
  }

  &__flag {
    &--wins {
      @include flag($color-win-flag);

      margin-right: 8px;
    }

    &--loss {
      @include flag($color-error);

      margin-right: 8px;
    }
  }

  &__rect {
    @include rect-flag;

    margin-right: 8px;
  }
}
