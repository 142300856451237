@use '~scss/settings' as *;

.bb-deposit-amount {
  @extend %modal-grid;

  width: 100%;

  &__content {
    grid-area: modal-main;
    overflow: auto;
    width: 100%;
    padding: 22px 16px;
    background-color: $color-background-primary;

    &__input-amount {
      width: 100%;

      > .bb-input-field {
        width: 100%;
        margin: 0px !important;

        > label {
          left: 50%;
          transform: translate3d(-50%, 0, 0);
        }

        .ncoded-input {
          min-height: 56px;
          border: 1px solid $color-alabaster;

          > input {
            text-align: center;
          }
        }
      }
    }

    &__cards {
      width: 100%;

      .bb-card {
        &--active {
          background-color: $color-background-secondary;
        }

        > svg {
          color: $color-active;
        }
      }
    }

    > p {
      @include font(16px, 24px, 700);

      color: $color-text-primary;
      margin: 0 0 16px;
    }

    > hr {
      @include size(100%, 1px);

      background-color: $color-alabaster;
      border: none;
      margin: 16px 0;
    }
  }

  &__footer {
    @extend %modal-footer;

    padding-bottom: 30px;
  }
}
