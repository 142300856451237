@use './color' as *;

$body-breakpoint: 500px;
$global-font-size: 16;
$overlay-window-z: 5;
$menu-z-index: 10;
$modal-z-index: 20;
$border-radius-standard: 8px;
$border-radius-round: 24px;
$padding-169: 56.25%;
$neon-box-shadow: var(--circle-box-shadow);
$reddish-box-shadow: 0 4px 16px -4px rgb(254, 91, 123, 0.76);

$button-height: 44px;
$modal-top: 64px;

$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
