@use '~scss/settings' as *;

.bb-user-bet {
  @include flex(column, center, center);

  width: 100%;
  min-width: 280px;
  min-height: 264px;
  border-radius: $border-radius-standard;

  &--active {
    border: 1px solid $color-active;
  }

  &__bet-message[class] {
    @include font(12px, 16px, 600);
    @include flex(row);

    color: $color-text-primary;
    margin: 0px;
    padding: 12px 16px;

    > svg {
      @include size(16px);

      min-width: 16px;
      margin-right: 4px;
      color: $color-yellowish;
    }
  }

  &__start-date {
    @include flex(column, center, center);
    margin: 0 0 4px;
  }

  &__header {
    @include flex(row, flex-start, center);
    @include border-top-radius($border-radius-standard);

    position: relative;
    width: 100%;
    padding: 12px;
    background-color: $color-player-card-background;

    > .bb-user-image {
      @include size(40px);

      margin-right: 12px;
      border-radius: 50%;

      img {
        @include size(100%);
      }
    }

    > div:nth-child(2) {
      > p:nth-child(1) {
        @include font(14px, 20px, 700);

        color: $color-text-primary;
        margin: 0px;
        letter-spacing: 0.5px;
        margin-bottom: 4px;
      }

      > p:nth-child(2) {
        @include font(10px, 16px, 700);

        color: $color-text-secondary;
        margin: 0px;
        letter-spacing: 0.5px;
      }
    }

    &__dropdown {
      margin-left: auto;

      &__trigger {
        @include size(32px);
      }

      .dots-button {
        @include size(100%);

        min-width: 0px;
        border-radius: 50%;
        color: $color-text-secondary;
        background-color: transparent;

        &:focus {
          background-color: $color-settings-options;
        }
      }
    }
  }

  &__teams {
    @include flex(row, center, center);
    @include flex-gap(12px, row);

    padding: 12px 8px;
    width: 100%;
    background-color: $color-player-card-background;

    > .bb-bet-team {
      flex: 1 0 0;

      > img {
        @include size(64px);

        border: 0px;
        box-shadow: none;
      }
    }

    &__container {
      @include flex(column, center, center);

      width: 20%;
    }

    &--info,
    &--league {
      @include flex(column, center, center);
      @include font(10px, 16px, 700);

      margin: 0px;
      color: $color-text-secondary;
      letter-spacing: 0.5px;
      margin-bottom: 4px;
      text-align: center;
    }

    &--vs {
      @include font(16px, 20px, 600);

      margin: 0 0 12px;
      color: $color-text-primary;
    }

    > svg {
      margin-right: 8px;
      color: $color-button-primary;
    }
  }

  &__content {
    @include flex(column, center, center);

    width: 100%;

    &__private {
      @include flex(row, space-between, center);

      width: 100%;
      padding: 12px;
      background-color: $color-player-card-background;
      border-bottom: 1px solid $color-alabaster;
    }

    &__accepted {
      @include flex(row, center, center);
      @include flex-gap(4px, row);

      background-color: $color-button-primary;
      padding: 4px 8px;
      border-radius: $border-radius-standard;

      > span {
        @include font(14px, 20px, 600);

        margin: 0;
        color: $color-white;
      }

      > svg {
        color: $color-button-primary;
      }
    }

    > .bb-outcome-details {
      border-bottom: 1px solid $color-alabaster;
      background-color: $color-player-card-background;
    }

    > .bb-user-list {
      background-color: $color-player-card-background;
      border-bottom: 1px solid $color-alabaster;
    }

    > p {
      @include font(10px, 16px, 700);

      margin: 0px;
      width: 100%;
      text-align: left;
      background-color: $color-player-card-background;
      padding: 12px 16px 0px 10px;
      letter-spacing: 0.5px;
      color: $color-text-secondary;
    }
  }

  &__footer {
    @include flex(column, center, center);

    width: 100%;
    background-color: $color-player-card-background;
    padding: 16px;

    > button {
      width: 100%;
    }

    &__content {
      @include flex(row, center, center);

      width: 100%;

      > div:last-child {
        padding: 4px;
        border-radius: 2px;
      }

      &__card {
        @include flex(column, center, center);

        flex: 1;

        > p {
          margin: 0px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }

        &--info {
          > p {
            &:nth-child(1) {
              @include font(16px, 24px, 600);

              color: $color-text-primary;
            }

            &:nth-child(2) {
              @include font(10px, 16px, 700);

              color: $color-text-secondary;
            }
          }
        }

        &--win,
        &--lose,
        &--refund {
          > p {
            color: $color-white;
            font-weight: 700;

            &:nth-child(2) {
              @include font(10px, 16px, 700);
            }
          }
        }

        &--refund {
          background-color: $color-yellowish;
        }

        &--win {
          background-color: $color-win-flag;
        }

        &--lose {
          background-color: $color-error;
        }
      }
    }
  }

  &__share {
    @include flex(row, center, center);
    @include border-bottom-radius($border-radius-standard);

    width: 100%;
    padding: 12px;
    background-color: $color-player-card-background;

    > .ncoded-button {
      width: 100%;
      color: $color-text-primary;
      background-color: $color-not-active-button;
    }
  }
}
