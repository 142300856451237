@use '~scss/settings' as *;

.bb-copy-to-clipboard {
  @include flex(row, flex-start, center);
  width: 100%;

  .bb-copy-to-clipboard__button {
    @include border-right-radius(0);
    background-color: $color-text-secondary;

    &.ncoded-button.copied {
      color: $color-text-secondary;
      background-color: $color-cards-background;
      border: 1px solid $color-text-secondary;
    }
  }

  &__code {
    @include flex(row, flex-start, center);
    @include size(100%, 44px);
    @include border-right-radius(4px);
    @include border(solid, top 1px right 1px bottom 1px, $color-text-secondary);

    font-size: 14px;
    padding: 8px 12px;
    color: $color-text-primary;
  }
}
