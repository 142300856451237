@forward 'slider';
@forward 'color';
@forward 'variables';
@forward 'breakpoints';
@forward 'tools/tools';
@forward 'resets';
@forward 'buttons';
@forward 'input';
@forward 'modals';
@forward 'form';
@forward 'flags';
@forward 'bet-status';
@forward 'loader';
@forward 'confirm';
@forward 'select';
@forward 'setting';
@forward 'range-slider';
@forward 'top-cheese-colors';

%font {
  font-family: AvenirNext;
  font-family: Inter;
}
