@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-border-radius' as *;
@use './tools/t-flex' as *;

%betbears-modal {
  @include border-top-radius(16px);

  > header {
    @include flex(row, center, center);
    @include border-top-radius(16px);
    @include size(100%, 56px);

    position: relative;
    background-color: $color-background-primary;
    color: $color-title-text;

    > button {
      width: fit-content;
      position: absolute;
      right: 16px;
      outline: none;
      cursor: pointer;
    }
  }
}

%standard-modal {
  @extend %betbears-modal;

  > header {
    position: relative;
    flex: 0 0 auto;

    > p {
      font-weight: 600;
      font-size: 12px;
      text-transform: capitalize;
      letter-spacing: 0.5px;
    }

    > button:nth-child(1) {
      position: absolute;
      left: 0;
      min-width: 0px;
      margin-left: 16px;

      > svg {
        @include size(24px);

        transform: rotate(180deg);
      }
    }
  }

  > main {
    @include flex(column, flex-start);

    padding: 0px;
    flex: 1 1 100%;
    overflow: auto;
    align-items: stretch;

    .input-container {
      width: 100%;
    }
  }
}

%modal-grid {
  height: calc(100% - 112px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'modal-main' 'modal-footer';

  [class$='footer'] {
    .ncoded-button {
      margin-bottom: 24px;
    }
  }
}

%modal-footer {
  @include flex(column, center, center);

  background-color: $color-modal;
  padding: 12px 16px;

  > button {
    width: 100%;
  }
}

%modal-edit-bet-footer {
  @extend %modal-footer;

  .save-edit-bet-button.ncoded-button:first-of-type {
    margin-bottom: 12px;
  }

  .ncoded-button--secondary {
    border: 1px solid $color-text-secondary;
  }
}

%filter-modal {
  @extend %betbears-modal;

  height: fit-content;

  > header {
    background-color: $color-alabaster;

    > p {
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      color: $color-text-primary;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  > main {
    height: 100%;

    > form {
      height: calc(100% - 112px);
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr;
      overflow: auto;
      width: 100%;
    }

    > p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      text-align: center;
      color: $color-error;
      text-transform: capitalize;
    }
  }
}
