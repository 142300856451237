@use '~scss/settings' as *;

.bb-newbet-modal {
  top: $modal-top;

  &__prev-btn[class] {
    width: fit-content;
  }

  &__container {
    @include size(100%);
    background-color: $color-background-primary;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    > form {
      @include flex(column, flex-start, center);
      @include size(100%);

      .bb-input-field {
        margin-top: 0px;
      }

      > button {
        width: 100%;

        &[type='submit'] {
          flex: 0 0 auto;
          margin-bottom: 24px;
        }
      }
    }
  }

  &__footer {
    @include flex(row, center, center);
    @include size(100%);

    background-color: $color-radio-slider;
    padding: 12px 16px;

    > button {
      width: 100%;
    }
  }

  &__step {
    @include size(100%);

    &-enter {
      @include absolute(top 0 left 0);
    }

    &-exit-active {
      .bb-appear-animation--move-in {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        animation-delay: 0ms;
        animation-name: moveOut, disappear;
      }
    }
  }

  .overlay {
    &__content {
      @extend %betbears-modal;
      @extend %standard-modal;

      > main {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;
        height: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      [class$='footer'] {
        box-shadow: 0px 25px 40px $color-active;
      }
    }
  }
}
