@use '~scss/settings' as *;

.day-picker {
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;

  &__week-days {
    color: $color-rang;
  }
}
