@use '~scss/settings' as *;

.bb-credit-card {
  @extend %modal-grid;

  &__container {
    @include flex-gap(16px, column);

    overflow: auto;
    width: 100%;
    padding: 24px 16px;
    background-color: $color-background-primary;

    > .ncoded-button {
      width: 100%;
    }

    > p {
      @include font(16px, 24px, 700);

      width: 100%;
      margin: 0px;
      color: $color-text-primary;
      text-align: left;
      margin-bottom: 16px;
      text-transform: capitalize;
    }
  }

  &__value {
    @include flex(row, center, flex-start);

    width: 100%;
    gap: 4px;

    .bb-input-field {
      width: 90%;

      .ncoded-input {
        border: 1px solid $color-alabaster;
      }
    }

    > .ncoded-button {
      @extend %secondary-border-button;

      width: 10%;
      min-width: 44px;
      position: relative;
      transform: translate3d(0, 5px, 0);
      border: 1px solid $color-alabaster;

      > svg {
        color: $color-alabaster;
      }
    }
  }

  &__footer {
    @extend %modal-footer;
  }

  &__active-button[class] {
    > svg {
      @include size(24px);

      color: $color-button-primary;
    }
  }
}

.wallet-qr-code {
  width: 100%;
  height: auto;
}
