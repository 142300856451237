@use '~scss/settings' as *;

.bb-range-slider-field {
  @include flex(row, center, center);

  width: 100%;
  height: fit-content;

  > span {
    @include font(16px, 18px, 500);
    color: $color-text-primary;
    text-align: center;
  }

  &__range-slider {
    margin: 0px 24px;
  }
}
