@use '~scss/settings' as *;

.bb-bets {
  @include flex(column, flex-start, center);
  @include size(100%);

  padding: 0 0 8px;
  overflow: auto;

  &__container {
    @include flex(column, center, center);
    overflow: auto;
    position: relative;
    padding: 0px 8px;

    flex: 1 1 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__loader {
    justify-content: flex-start;
    position: relative;
    margin: 24px 0px;
  }

  &__radio-slider {
    @extend %slider-primary;
    @extend %slider--sports-list;

    height: 40px;
    margin: 24px 0;
    padding: 12px 0;
  }

  > .bb-appear-animation {
    width: 100%;
    overflow: auto;
  }

  .bb-animated-radio {
    @include size(100%);
    flex: 1 1 100%;

    &__content {
      flex: 1 1 100%;
    }
  }
}
