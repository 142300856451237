@use '~scss/settings' as *;

.bb-user-bet-info {
  @include flex(row, flex-start, center);

  position: relative;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid $color-alabaster;

  .bb-user-bet-info__user-image {
    img {
      @include size(40px);
    }

    padding-right: 16px;
    border-radius: 50%;
  }

  &__details {
    line-height: 16px;

    &__name {
      font-size: 14px;
      color: $color-text-primary;
    }

    &__time {
      font-size: 10px;
      color: $color-text-secondary;
    }
  }
}
