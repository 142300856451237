@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-flex' as *;
@use './tools//t-border-radius' as *;

@mixin slider-secondary {
  overflow: auto;
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;

  &::before {
    z-index: 1;
    box-shadow: none;
    border-radius: 8px;
    background-color: $color-radio-slider;
    padding: 0px 1px;
  }

  > label {
    @include flex(row, center, center);
    flex: 1;

    margin: 0px;
    color: $color-text-primary;
    font-size: 12px;
    border: 1px solid $color-radio-slider;
    border-radius: 8px;
    z-index: 1;
  }
}

%slider-primary {
  overflow: auto;
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;

  > label:first-of-type {
    @include flex(row, center, center);
    flex: 0 0 64px;

    padding: 10px 0px;
    border-radius: $border-radius-round;
  }

  .bb-radio-slider__selected {
    &:first-child {
      border: 0px;
    }
  }

  > label {
    z-index: 1;
  }

  &::before {
    z-index: 1;
  }
}

%slider-secondary {
  @include border-left-radius($border-radius-standard);
  @include border-right-radius($border-radius-standard);

  overflow: auto;
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
  height: 44px;
  border-radius: 20px;
  background-color: $color-player-card-background;

  &::before {
    height: 100%;
    z-index: 1;
    box-shadow: none;
    background-color: $color-active;
  }

  &.bb-radio-slider--last-selected {
    &::before {
      border-radius: 20px;
    }
  }

  &.bb-radio-slider--first-selected {
    &::before {
      border-radius: 20px;
    }
  }

  > label {
    @include flex(row, center, center);
    flex: 1;

    margin: 0px;
    font-size: 12px;
    font-weight: 700;
    z-index: 1;
    color: $color-text-primary;
  }

  .bb-radio-slider__selected {
    opacity: 1;
  }
}

%slider-rect {
  overflow: auto;
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;

  &::before {
    z-index: 1;
    box-shadow: none;
    border-radius: $border-radius-standard;
  }

  .bb-radio-slider__selected {
    border: 0px;
    transition-delay: 100ms;
    transition-property: color;
    color: $color-white;
  }

  > label {
    @include flex(row, center, center);
    flex: 1;

    z-index: 1;
    color: $color-text-primary;
    font-size: 12px;
    font-weight: 700;
    border-radius: $border-radius-standard;

    &:not([class*='selected']) {
      background-color: $color-player-card-background;
      z-index: 0;
    }
  }
}

%slider--sports-list {
  > label {
    padding: 0px 12px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 24px;

    > p {
      font-size: 14px;
      font-weight: 600;
      margin: 0px;
      padding: 10px 0px;
      z-index: 4;
    }
  }

  > label:not([class*='selected']) {
    background-color: var(--color-player-card-background);
    z-index: 0;
  }
}
