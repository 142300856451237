@use '~scss/settings' as *;

.modal {
  @include flex(row, center, center);
  z-index: $overlay-window-z + 2;

  .overlay__content {
    @include size(100%);

    transition: transform 0.5s ease-in;
    background-color: $color-background-primary;

    > header {
      > button {
        background: transparent;
        border: 0px;
        > svg {
          @include size(22px);

          color: $color-text-secondary;
        }
      }
    }
  }
}
