@use '~scss/settings' as *;

.bb-bet-players-list {
  width: 100%;
  border-bottom: 1px solid $color-alabaster;

  &__private {
    @include flex(row, space-between, center);

    width: 100%;
    padding: 12px;

    &__accepted {
      @include flex(row, center, center);
      @include flex-gap(4px, row);

      padding: 4px 8px;
      border-radius: $border-radius-standard;
      background-color: $color-button-primary;

      > span {
        @include font(10px, 16px, 600);

        margin: 0;
        color: $color-white;
      }

      > svg {
        @include size(20px);
        color: $color-button-primary;
      }
    }
  }
}
