$color-white: #fff;

$color-button-primary: var(--color-button-primary);
$color-active: var(--color-active);
$color-active-secondary: var(
  --color-active-secondary
); //navigation-tabs, modules-confirm
$color-background-primary: var(--color-background-primary);
$color-background-secondary: var(--color-background-secondary);
$color-text-primary: var(--color-text-primary);
$color-text-secondary: var(--color-text-secondary);
$color-title-text: var(--color-title-text);
$color-header: var(--color-header);
$color-text-flags: var(--color-text-flags); // + withdraw button
$color-unique-button: var(--color-unique-button);
$color-bet-status: var(--color-bet-status);
$color-sport-icon: var(--color-sport-icon);
$color-settings-options: var(--color-settings-options);
$color-modal: var(--color-modal); //background for modal, select, dropdown
$color-disabled: var(--color-disabled);
$color-amount-slider-line: var(--color-amount-slider-line);
$color-bet-cards: var(--color-bet-cards);
$color-selected-option-background: var(--color-selected-option-background);
$color-player-card-background: var(--color-player-card-background);
$color-button-text: var(--color-button-text); //not all buttons
$color-confirm-overlay: rgba($color-bet-status, ce);
$color-deposit-button: rgb(var(--color-button-primary-values));

$color-cards-background: var(--color-cards-background);
$color-radio-slider: var(--color-radio-slider);
$color-rang: var(--color-rang);

$color-yellowish: #ffd458;
$color-reddish: #fe5b7b;
$color-reddish-opacity: rgb(254, 91, 123, 0.25);
$color-error: #db2828;

$color-button-primary-linear: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.71)
  ),
  linear-gradient(124deg, $color-active-secondary, #26e196 100%);

$color-gray-48: #484848;
$color-gray-99: #999;
$color-gray-e7: #e7e7e7;

$color-alabaster: #f2ece1;
$color-win-flag: rgb(38, 225, 150);
$color-not-active-button: rgba(#a39989, 0.3);
$color-search: #f0eae1;
$color-dutch-white: #e6d5b8;
$color-success: #51b056;
