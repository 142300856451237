@use '~scss/settings' as *;

.bb-forgot-password-modal {
  top: $modal-top;

  &__text {
    @include font(14px, 20px, 600);

    margin: 0 0 12px;
    color: $color-text-primary;
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      > p {
        @include font(12px, 16px, 700);

        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $color-text-primary;
      }
    }

    > main {
      padding: 22px;

      > form {
        > .bb-input-field {
          margin-bottom: 16px;
        }

        > .ncoded-button {
          @include size(100%, $button-height);

          margin-bottom: 6px;

          &:last-child {
            font-weight: 700;
            text-decoration: none;
          }

          &--link {
            color: $color-text-secondary;
          }
        }
      }
    }
  }
}
