@use '~scss/settings' as *;

.header {
  @include flex(row, space-between, center);

  background: var(--color-container-background);
  color: var(--color-text-primary);
  padding: 8px 6px 18px;
  flex: 1 1 100%;
  width: 100%;
  margin: 0 0 12px;

  &__heading {
    @include flex(row, flex-start, center);
    @include flex-gap(8px);

    cursor: pointer;
    color: $color-text-primary;

    &__rotated {
      transform: rotate(180deg);
    }

    & svg {
      transition: transform 0.25s;
    }

    > p {
      @include font(14px, 24px, 700);
      @include flex-gap(8px, row);

      margin: 0;
    }
  }

  svg {
    @include size(24px);

    cursor: pointer;
    color: $color-text-primary;
    padding: 4px;
  }
}
