@use '~scss/settings' as *;

.bb-team {
  @include flex(column);
  @include size(100%);
  @include flex-gap(8px, column);

  overflow: hidden;
  padding: 0px 8px 8px;
  background-color: $color-background-primary;
  margin-top: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__team {
    @include flex(column, center, center);

    width: 100%;
    padding: 16px;
    background-color: $color-alabaster;
    border-radius: $border-radius-standard;

    .bb-team-card {
      background-color: transparent;
      margin-bottom: 12px;
      min-height: 80px;
      border: none;

      > img {
        @include size(64px);
      }
    }

    > button {
      width: 100%;
    }

    > button:first-of-type {
      @include size(18px);

      position: absolute;
      right: 16px;
      top: 16px;
      color: $color-text-secondary;
      background-color: transparent;
      min-width: 0px;

      > svg {
        @include size(100%);

        margin: 0px;
      }
    }
  }

  &__animated-slider {
    flex: 1 1 100%;

    .bb-animated-radio__content {
      overflow: auto;
      flex: 1 1 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__select {
    @extend %slider-secondary;

    margin: 8px 0px;
  }
}
