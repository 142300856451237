@use '~scss/settings' as *;

.bb-navigation-tab {
  @include flex(column, center, center);
  @include size(100%);

  position: relative;
  text-decoration: none;
  cursor: pointer;
  color: #1b1a17;
  padding: 12px 0px 10px;

  &__dot {
    @include flex(row, center, center);
    @include size(9px);

    position: absolute;
    top: 3px;
    right: 0;
    background-color: var(--color-button-primary);
    border-radius: 50%;
    font-size: 10px;
    color: $color-text-secondary;
    color: $color-text-primary;
    border: 2px solid $color-background-secondary;
  }

  &--active {
    color: $color-active-secondary;
  }

  > div:nth-child(1) {
    position: relative;

    > svg {
      object-fit: cover;
    }
  }

  > span {
    @include font(10px, 16px, 700);

    margin: 0px;
    text-transform: uppercase;
  }
}
