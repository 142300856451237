@use '~scss/settings' as *;

.bb-mybets {
  @include flex(column, flex-start, center);

  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &__slider {
    @extend %slider-secondary;
  }

  &__container {
    @include size(100%);

    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
    padding: 0 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    > .bb-infinite-scroll {
      @include flex-gap(8px, column);
    }
  }

  &__header-wrapper {
    width: 100%;
    margin-bottom: 8px;
    background-color: $color-text-primary;
  }

  &__header {
    @include flex(row, flex-end, center);
    @include size(100%);
    @include border-top-radius(16px);

    position: relative;
    padding: 10px 10px 10px 16px;
    background-color: $color-background-primary;

    &__title {
      @include absolute(top 50% left 50%);
      @include font(12px, 16px, 600);

      margin: 0px;
      color: $color-text-primary;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      transform: translate3d(-50%, -50%, 0);
    }

    > .ncoded-button {
      @extend %action-round-button;

      > svg {
        margin: 0;
      }
    }
  }
}
