@use '~scss/settings' as *;

.bb-friendship-card {
  @include flex(row, space-between, center);

  width: 100%;
  padding: 14px 0;
  text-decoration: none;

  > a {
    @include flex(row, flex-start, center);

    text-decoration: none;
    width: 100%;

    > .bb-user-image {
      margin-right: 12px;
    }

    > p {
      @include font(14px, 20px, 600);

      margin: 0px;
      color: $color-text-primary;
      letter-spacing: 0.5px;
    }

    > img {
      @include size(40px);

      object-fit: contain;
      margin-right: 12px;
    }
  }

  > .ncoded-button {
    @include font(12px, 16px, 600);

    height: 28px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-radius: 4px;

    &--primary {
      background-color: black;
    }

    &--secondary {
      @include font(12px, 16px, 600);

      color: $color-text-secondary;
      font-weight: 700;
      border: 1px solid $color-text-secondary;

      > svg {
        @include size(14px);

        color: $color-button-primary;
        margin: 0px;
        margin-left: 8px;
      }
    }
  }
}
