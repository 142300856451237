@use '~scss/settings' as *;

.bb-time-filter-modal {
  @include flex(row, center, flex-end);
  top: $modal-top;

  &__calendar {
    width: 100%;

    .bb-calendar {
      width: 100%;
    }
  }

  &__container {
    width: 100%;
    padding: 16px 12px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    > p {
      @include font(10px, 16px, 700);

      margin: 0px;
      color: $color-text-secondary;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }

    > .bb-radio-slider {
      @extend %slider-rect;

      > label {
        @include font(12px, 36px, 700);
      }
    }
  }

  &__footer-without-margin {
    @include flex(row, center, center);
    @include size(100%, auto);
    @extend %modal-footer;

    border-top: 1px solid $color-alabaster;
    padding: 12px 12px 18px;

    > button {
      width: 100%;
    }

    > button:first-of-type {
      margin-right: 12px;
      color: $color-text-secondary;
      background-color: transparent;
      background-color: $color-not-active-button;
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    height: 100%;

    > header {
      border-bottom: 1px solid $color-alabaster;

      > p {
        @include font(12px, 16px, 700);

        color: $color-text-primary;
        text-transform: uppercase;
      }
    }

    > main {
      height: 100%;
      > form {
        @extend %modal-grid;
      }
      > p {
        @include font(12px, 16px, 600);

        text-align: center;
        color: $color-error;
        text-transform: capitalize;
      }
    }
  }
}
