@use '~scss/settings' as *;

.bb-profile {
  width: 100%;
  padding: 0px 8px;

  > * {
    margin-bottom: 8px;
  }

  &__profile-settings {
    @include absolute(top 16px right 16px);

    text-decoration: inherit;
    color: $color-text-secondary;
  }

  &__content {
    @include flex(column, center, center);

    width: 100%;
    border-radius: $border-radius-standard;
    position: relative;

    &__user {
      @include border-top-radius($border-radius-standard);

      display: grid;
      position: relative;
      grid-template-columns: 64px 1fr;
      grid-gap: 24px;
      padding: 16px;
      background-color: $color-alabaster;
      margin-top: 12px;
      width: 100%;

      > .bb-user-image {
        @include size(64px);

        margin-right: 24px;

        > img {
          @include size(100%);
        }
      }

      > div:nth-child(2) {
        @include flex(column, center, flex-start);

        width: 100%;

        > p:nth-child(1) {
          margin: 0px;
          color: $color-text-primary;
          margin-bottom: 12px;
        }

        > p:nth-child(2) {
          margin: 0px;
          color: $color-text-secondary;
          font-size: 10px;
          text-transform: uppercase;
        }
      }
    }

    &__container {
      @include flex(column, center, center);
      @include border-bottom-radius($border-radius-standard);

      width: 100%;
      border: 1px solid $color-alabaster;

      &__data {
        @include flex(row, center, center);

        width: 100%;

        > div {
          @include flex(column, center, center);

          width: 33%;
          padding: 16px;

          > p {
            margin: 0px;
          }

          > p:nth-child(1) {
            @include font(16px, 24px, 600);

            color: $color-text-primary;
            letter-spacing: 0.5px;
            margin-bottom: 4px;
          }

          > p:nth-child(2) {
            @include font(10px, 16px, 700);

            color: $color-disabled;
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }
        }
      }

      &__actions {
        @include flex(row, center, center);

        width: 100%;
        padding: 0 16px 16px 16px;

        > .ncoded-button {
          width: 100%;

          &--secondary {
            color: $color-white;
            background-color: $color-text-primary;
          }

          &:first-child {
            margin-right: 12px;
          }
        }
      }
    }

    > p {
      @include font(12px, 16px, 500);

      color: $color-text-secondary;
      letter-spacing: 0.5px;
      margin: 0px;
      padding: 24px 16px;
    }

    > .ncoded-button {
      @include size(24px);

      position: absolute;
      min-width: 0px;
      background-color: transparent;
      top: 20px;
      right: 15px;
    }
  }
}
