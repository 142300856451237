@use '~scss/settings' as *;

.bb-bet-amount {
  --amount-slider-padding: 0px;

  @extend %modal-grid;

  > small {
    color: $color-error;
    font-size: 14px;
  }

  .bb-radio-slider {
    @extend %slider-rect;

    margin-top: 24px;
    padding: 0px var(--amount-slider-padding);

    &:before {
      --offset: calc(var(--xOffset) - var(--amount-slider-padding));
      transform: translate3d(var(--offset), 0, 0);
      padding: 0;
    }

    > label {
      @include font(12px, 16px, 600);

      padding: 12px 0px;
      color: $color-text-secondary;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__container {
    @include flex(column, flex-start, flex-start);

    grid-area: modal-main;
    margin-top: 16px;
    padding: 0 16px;
    overflow: hidden;
    width: 100%;

    > label {
      @include font(10px, 16px, 600);

      color: $color-rang;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }

    > label:nth-child(4) {
      margin-top: 24px;
    }
  }

  &__footer {
    @extend %modal-edit-bet-footer;
  }

  &__game {
    @include flex(row, center, center);

    margin-top: 10px;

    > label {
      color: $color-text-primary;
    }

    &__details {
      @include flex(column, flex-end, center);

      width: 30%;
      padding: 12px 0px;

      > p {
        margin: 0px;
      }

      > p:first-child {
        font-size: 10px;
        font-weight: 700;
        color: $color-text-secondary;
        margin-bottom: 8px;
      }

      > p:nth-child(2) {
        color: $color-text-primary;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 32px;
      }

      > p:nth-child(3) {
        font-size: 12px;
        color: $color-text-secondary;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
}
