@use '~scss/settings' as *;

.bb-card {
  @include flex(row, center, center);
  @include font(14px, 20px, 700);
  @include size(100%, 56px);

  cursor: pointer;
  color: $color-text-secondary;

  &--active {
    @include font(14px, 20px, 700);

    position: relative;
    color: $color-text-primary;
    border-radius: $border-radius-standard;
    background-color: $color-background-primary;

    > svg {
      position: absolute;
      right: 24px;
    }
  }
}
