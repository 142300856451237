@use '~scss/settings' as *;

.bb-radio-slider-filed {
  @include flex(row, flex-start, flex-start);

  width: 100%;

  &--error {
    &::before {
      background-color: $color-reddish;
    }
  }
}
