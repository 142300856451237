@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools//t-flex' as *;

%select-standard {
  .dropdown__content[class] {
    border: 1px solid $color-rang;
  }

  &__placeholder {
    padding: 10px 20px;
  }

  &__selected {
    height: 44px;
    border: 1px solid $color-disabled;
  }

  &:focus-within:not(&--error) &__selected {
    border-color: $color-rang;
  }
}

@mixin select-style {
  .ncoded-select {
    @extend %select-standard;
    --color-button-primary: #{$color-rang};

    &:focus-within:not(.ncoded-select--error) .ncoded-input {
      border-color: $color-alabaster;
    }

    &__value {
      .ncoded-input {
        padding: 0px 10px;
        color: $color-text-primary;
        border-color: $color-alabaster;
      }
    }

    &__options {
      background-color: $color-background-secondary;
    }

    &__selected {
      border: 1px solid $color-rang !important;
      padding: 0px 20px;
      > span {
        color: $color-text-primary;
      }

      .dropdown-icon {
        color: $color-text-primary;
      }
    }

    &__option {
      width: 100%;
      position: relative;
      background-color: $color-background-secondary;
      padding: 10px 10px 10px 20px;
      color: $color-text-secondary;

      &--selected {
        background-color: $color-selected-option-background;
        font-weight: 600;
      }

      > * {
        flex-shrink: 0;
      }

      > span {
        margin-right: 10px;
      }

      &:hover {
        background-color: $color-modal;
        color: $color-text-secondary;
        font-weight: 600;
      }

      &::after {
        margin-right: 14px;
      }
    }
  }
}
