@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-flex' as *;
@use './tools//t-border-radius' as *;

%relative-loader {
  position: relative;
  height: auto;
  background-color: transparent;
  padding: 4px 0px;
}
