@use '~scss/settings' as *;

.bb-user-image {
  position: relative;

  > img {
    @include flex(row, center, center);
    @include size(48px);

    border-radius: 50%;
    object-fit: cover;
  }

  &--error {
    @include size(48px);
  }

  &--active {
    @include size(10px);

    position: absolute;
    bottom: 4px;
    right: 2px;
    background-color: $color-button-primary;
    border-radius: 50%;
    z-index: 1;
    border: 2px solid $color-cards-background;
  }
}
