@use '~scss/settings' as *;

$label-font-size: 10px;
.bb-input-field {
  width: 100%;
  position: relative;

  padding-top: $label-font-size / 2;

  > label {
    @include absolute(top 0 left 20px);

    font-size: $label-font-size;
    text-transform: uppercase;
    margin: 0px;
    z-index: 1;
    background-color: $color-background-primary;
    color: $color-rang;
    padding: 0px 4px;
    border-radius: 4px;
    font-weight: 700;
  }

  > .ncoded-input {
    height: 44px;
    border: 1px solid $color-alabaster;

    &--error {
      border-color: #fe5b7b;
    }

    > input {
      padding: 10px 20px;
    }
  }

  &__error {
    > small {
      color: $color-reddish;
    }

    > label {
      color: $color-reddish;
    }
  }
}
