@use '~scss/settings' as *;

.bb-bet {
  @include flex(column, center, center);

  width: 100%;
  border-radius: $border-radius-standard;
  margin-top: 4px;

  &__header {
    @include flex(row, flex-start, center);
    @include border-top-radius($border-radius-standard);

    width: 100%;
    padding: 12px;
    background-color: $color-background-primary;
    position: relative;

    > .bb-user-image {
      object-fit: cover;
      margin-right: 12px;
      border-radius: 50%;
    }

    > div:nth-child(2) {
      > p:nth-child(1) {
        @include font(14px, 20px, 700);

        margin: 0px;
        color: $color-text-primary;
        letter-spacing: 0.5px;
        margin-bottom: 4px;
      }

      > p:nth-child(2) {
        @include font(10px, 16px, 700);

        margin: 0px;
        color: $color-text-secondary;
        letter-spacing: 0.5px;
      }
    }
  }

  &__status {
    @extend %bet-status;

    &__text[class] {
      @include font(10px, 12px, 700);

      margin: 0px;
      text-transform: uppercase;
      color: $color-white;
      padding: 2px 5px;
      border-radius: 4px;
    }

    &--win {
      background-color: $color-button-primary;
    }

    &--lose {
      background-color: $color-reddish;
    }

    &--refund {
      background-color: $color-yellowish;
    }
  }

  &__content {
    @include flex(column, center, center);

    width: 100%;
    background-color: $color-background-primary;

    &__team {
      @include flex(row, center, center);

      width: 100%;
      padding: 12px;
      background-color: $color-player-card-background;

      &__container {
        @include flex(column, center, center);

        width: 18%;
      }

      &--start-date {
        @include flex(column, center, center);
        @include font(10px, 16px, 700);

        margin: 0 0 4px;
        color: $color-text-secondary;
      }

      &--vs {
        @include font(16px, 20px, 700);

        color: $color-text-primary;
        margin: 0 0 10px;
      }

      &--league,
      &--info {
        @include flex(column, center, center);
        @include font(10px, 16px, 700);

        margin: 0 0 4px;
        color: $color-text-secondary;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }

    div > .bb-bet-team {
      flex: 1 0 0;

      > img {
        @include size(64px);
      }
    }
  }

  &__bet-message {
    @include font(12px, 14px, 600);
    @include size(100%, auto);

    margin: 0px;
    padding: 12px 12px 0;
    color: $color-text-primary;
    background-color: $color-bet-cards;
  }

  &__footer {
    @include flex(column, center, center);

    width: 100%;
    background-color: $color-background-primary;
    padding: 16px;
    border-bottom: 1px solid $color-alabaster;

    &__content {
      @include flex(row, center, center);

      width: 100%;

      > div {
        @include flex(column, center, center);
        flex: 1;

        > p:nth-child(1) {
          @include font(16px, 24px, 600);

          margin: 0px;
          color: $color-text-primary;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }

        > p:nth-child(2) {
          @include font(10px, 16px, 700);

          color: $color-text-secondary;
          margin: 0px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
    }

    > button {
      margin-top: 24px;
      width: 100%;
    }
  }
}
