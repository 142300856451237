@use '~scss/settings' as *;

.bb-outcome-details-card {
  @include flex(column, center, center);

  width: 100%;
  border-bottom: 1px solid $color-alabaster;
  padding: 12px;

  &__collapsible {
    .bb-collapsible__trigger {
      width: 100%;
    }
  }

  &__team {
    @include size(40px);

    object-fit: contain;
  }

  &__content {
    @include flex(row, space-between, center);

    width: 100%;

    > div:nth-child(1) {
      @include flex(row, center, center);
      @include flex-gap(8px, row);

      > p {
        @include font(14px, 20px, 700);

        color: $color-text-primary;
        margin: 0px;
      }
    }

    &__advanced {
      @include font(10px, 16px, 700);

      border: 1px solid $color-text-secondary;
      border-radius: 24px;
      letter-spacing: 0.5px;

      > svg {
        @include size(16px);
      }
    }

    > .ncoded-button {
      background-color: transparent;
      text-transform: uppercase;
      min-width: 0px;
      height: 20px;
      padding: 0px 0px 0px 14px;
      color: $color-text-secondary;

      > svg {
        @include size(24px);

        transform: rotate(90deg);
        transition: transform 150ms ease-in-out;
      }
    }
  }

  &__data {
    @include flex(column, flex-start, flex-start);

    width: 100%;
    margin-top: 24px;

    > div {
      @include flex(row, space-between, center);

      width: 100%;
      margin-bottom: 12px;

      > p:nth-child(1) {
        text-transform: uppercase;
        font-weight: 700;
      }

      > p:nth-child(2) {
        @include font(12px, 16px, 700);

        letter-spacing: 0.5px;
        color: $color-text-primary;
      }
    }

    p {
      @include font(10px, 16px, 600);

      margin: 0px;
      color: $color-text-secondary;
      letter-spacing: 0.5px;
    }
  }

  &--open {
    .ncoded-button {
      > svg {
        transform: rotate(-90deg);
        transition: transform 150ms ease-in-out;
      }
    }
  }
}
