@use '~scss/settings' as *;

.bb-teams {
  @include flex(column, flex-start, center);

  width: 100%;
  overflow: auto;
  margin-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    @include size(100%);

    display: grid;
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
    padding: 0px 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    > .bb-radio-slider {
      @extend %slider-primary;
      @extend %slider--sports-list;

      margin: 12px 0px;
    }
  }

  &__header {
    @extend %menu-header;

    background-color: $color-alabaster;
  }

  &__content {
    @include flex(row);

    width: 100%;
    margin-bottom: 12px;

    .ncoded-input {
      @extend %input-rounded;
      @include size(100%, 36px);

      border: 0px;
      padding: 0px 8px;
      margin-right: 12px;
      background-color: $color-search;

      &:focus-within {
        border: 0px;
      }

      > input::placeholder {
        color: $color-disabled;
      }
    }

    .ncoded-button {
      @include size(36px);
      @extend %primary-button;

      background-color: $color-active;
      color: $color-white;
      border-radius: $border-radius-round;
      min-width: 36px;
    }
  }
}
