@use '~scss/settings' as *;

.bb-auth-modal {
  .overlay__content {
    @extend %betbears-modal;

    > header {
      > p {
        @include font(12px, 16px, 700);

        letter-spacing: 0.5px;
        color: $color-text-primary;
      }
    }

    > main {
      padding: 22px;

      > form {
        > .bb-input-field {
          margin-bottom: 16px;
        }

        > .ncoded-button {
          @include size(100%, $button-height);
          margin-bottom: 6px;

          &:last-child {
            font-weight: 700;
            text-decoration: none;
            line-height: 20px;
            letter-spacing: 0.5px;
            border: none;
          }

          &--link {
            color: $color-text-secondary;
          }
        }
      }

      > div {
        @include flex(row, center, center);
        @include size(100%, 32px);

        border-radius: $border-radius-standard;
        background-color: $color-reddish-opacity;

        > p {
          @include font(12px, 16px, 500);

          margin: 0px;
          text-align: center;
          color: $color-reddish;
          opacity: 1;
        }
      }
    }
  }
}
