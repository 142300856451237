@use '~scss/settings' as *;

.bb-bet-status {
  @extend %bet-status;

  &__text {
    @include font(12px, 16px, 600);
    text-align: center;
  }

  &--outline {
    top: initial;
    padding: 2px 6px;

    &--not-started-yet {
      border: 1px solid $color-text-secondary;
      color: $color-text-secondary;
    }

    &--finished {
      border: 1px solid $color-success;
      color: $color-success;
    }

    &--in-progress {
      border: 1px solid $color-active;
      color: $color-active;
    }
  }

  &--solid {
    > p {
      @include font(10px, 16px, 600);

      margin: 0px;
      color: $color-bet-status;
    }

    > svg {
      @include size(16px);

      margin-right: 8px;
    }

    &--in-progress,
    &--close,
    &--open {
      background-color: $color-background-primary;

      > p {
        @include font(8px, 12px, 700);

        color: $color-text-secondary;
        text-transform: uppercase;
      }
    }

    &--win,
    &--lose,
    &--refund {
      > p {
        @include font(8px, 12px, 700);

        color: $color-white;
        text-transform: uppercase;
      }
    }

    &--win {
      background-color: $color-win-flag;

      > svg {
        color: $color-button-primary;
      }
    }

    &--lose {
      background-color: $color-error;

      > svg {
        color: $color-reddish;
      }
    }

    &--refund {
      background-color: $color-yellowish;

      > svg {
        color: $color-yellowish;
      }
    }
  }
}
