@use '~scss/settings' as *;

.bb-choose-category {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding: 8px;

  > small {
    color: $color-error;
    width: 100%;
  }
}
