@use '~scss/settings' as *;

.bb-betchat-card {
  @include flex(column, center, center);

  border-radius: 16px 16px 0px 16px;

  &--active {
    border: 1px solid $color-active;
  }

  &__header {
    @include flex(row, space-between, center);
    @include border-top-radius(14px);

    position: relative;
    width: 100%;
    padding: 16px;
    background-color: $color-background-primary;
    border-bottom: 1px solid $color-alabaster;

    &__user {
      @include flex(row, center, center);

      > .bb-user-image {
        @include flex(row, center, center);
        margin-right: 16px;

        > img {
          @include size(40px);
        }
      }

      &-info {
        @include flex(column, center, flex-start);

        > p:nth-child(1) {
          @include font(14px, 16px, 600);

          color: $color-text-primary;
          margin: 0px;
          letter-spacing: 0.5px;
        }

        p:nth-child(2) {
          @include font(10px, 16px, 600);

          color: $color-text-secondary;
          margin: 0px;
          letter-spacing: 0.5px;
        }
      }
    }

    &__betting-ticket-info {
      @include font(12px, 16px, 600);

      padding: 2px 5px;
      text-align: center;
      margin: 0px !important;
      border: 1px solid $color-text-secondary;
      border-radius: 4px;
    }
  }

  &__status {
    @extend %bet-status;

    padding: 2px 4px;

    &__text[class] {
      @include font(8px, 16px, 700);

      margin: 0px;
      text-transform: uppercase;
      color: $color-radio-slider;
    }

    &--win {
      background-color: $color-button-primary;
    }

    &--lose {
      background-color: $color-reddish;
    }

    &--close,
    &--open,
    &--refund {
      background-color: $color-yellowish;
    }
  }

  &__teams {
    @include flex(column, center, center);

    width: fit-content;
    padding: 0px 4px;

    &--info[class] {
      @include flex(column, center, center);
      @include font(10px, 16px, 700);

      margin: 0px;
      text-align: center;
      color: $color-text-secondary;
      letter-spacing: 0.5px;
      margin-bottom: 4px;
    }

    &--vs[class] {
      @include font(16px, 20px, 600);

      color: $color-text-primary;
      margin: 0 0 12px;
      text-align: center;
    }
  }

  &__content {
    @include flex(column, center, stretch);

    width: 100%;
    background-color: $color-background-primary;
    padding: 20px 12px 12px;

    &__details {
      @include flex(row, flex-start, flex-start);
      @include font(10px, 16px, 600);

      &--sport {
        width: 40% !important;
        margin: 0px !important;
      }
      &--league {
        width: 40% !important;
        margin: 0px !important;

        text-align: end;
      }

      &--time {
        @include flex(column, center, center);

        width: 40% !important;
        text-align: center;
        gap: 4px;

        > p {
          margin: 0px !important;

          &:first-child {
            @include font(20px, 16px, 600);

            color: $color-text-primary;
          }
        }
      }
    }

    &__teams {
      @include flex(row, center, stretch);

      gap: 8px;
      padding: 20px 0 6px;

      > .bb-bet-team {
        @include flex(column, center, center);
        flex: 1 0 0;

        > label {
          @include font(10px, 16px, 600);

          word-break: normal;
        }

        > img {
          @include size(48px);
        }
      }
    }
  }

  &__footer {
    @include flex(row, center, center);

    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 0px;
    width: 100%;
    background-color: $color-background-primary;
    padding: 14px 12px;
    border-top: 1px solid $color-alabaster;

    > div {
      @include flex(column, center, center);
      flex: 1;

      > p {
        text-align: center;
      }

      > p:nth-child(1) {
        @include font(14px, 20px, 700);

        margin: 0px;
        color: $color-text-primary;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      > p:nth-child(2) {
        @include font(10px, 16px, 600);

        margin: 0px;
        color: $color-text-secondary;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }
  }

  &__share {
    @include flex(row, center, center);
    @include border-bottom-radius($border-radius-standard);

    width: 100%;
    padding: 12px;
    background-color: $color-bet-cards;

    > .ncoded-button {
      width: 100%;
      background-color: $color-disabled;
      color: $color-text-secondary;
      border: 1px solid $color-text-secondary;
    }
  }
}
