@use '~scss/settings' as *;

.bb-bet-type {
  @extend %modal-grid;

  &__container {
    @include flex(row, flex-start, flex-start);

    width: 100%;
    padding: 0px 16px;
    margin-top: 16px;
  }

  &__footer {
    @extend %modal-edit-bet-footer;
  }
}
