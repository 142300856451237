@use '~scss/settings' as *;

.betbears-progress {
  width: 100%;
  position: relative;
  background-color: $color-alabaster;

  &__progress {
    &__line-container {
      background-color: $color-active;
      height: 2px;
      box-shadow: $neon-box-shadow;

      &--error {
        background-color: $color-error;
      }
    }

    &__step {
      @include flex(row, center, center);

      position: absolute;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $color-background-primary;
      color: $color-text-primary;
      opacity: 1;

      &--active {
        background-color: $color-background-primary;
        color: $color-gray-48;
      }
    }
  }
}
