@use '~scss/settings' as *;

.bb-settings-card {
  @include flex(column, center, center);

  width: 50%;
  padding: 32px 16px 20px;
  border-radius: $border-radius-standard;
  transition: background 200ms ease-in-out;

  &--active {
    background-color: $color-background-secondary;

    .bb-settings-card__circle {
      transition: background 200ms ease-in-out;
      background-color: $color-active;
      box-shadow: $neon-box-shadow;
      color: $color-background-secondary;
    }

    > p:nth-child(3) {
      color: $color-text-primary;
    }
  }

  > p:nth-child(3) {
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  > p {
    @include font(10px, 16px, 700);

    text-align: center;
    text-transform: uppercase;
    color: $color-text-secondary;
    margin: 4px 0px;
    letter-spacing: 0.5px;
    max-width: 128px;
  }

  &__circle {
    @include flex(row, center, center);
    @include size(64px);

    border-radius: 50%;
    background-color: $color-background-primary;
    margin-bottom: 24px;
    color: $color-text-secondary;
  }
}
