@use '~scss/settings' as *;

$bottom-offset: 8;
$height: 64;

$translateY: -100% + ($bottom-offset * 100 / $height);

.bb-toast {
  @include fixed(left 50%);
  @include flex(row, space-between, center);

  filter: drop-shadow(2px 2px 28px rgba(28, 34, 79, 0.25));
  top: #{$bottom-offset}px;
  height: #{$height}px;
  width: 95%;
  z-index: 999999;
  background-color: $color-player-card-background;
  border-radius: $border-radius-standard;
  color: $color-text-primary;
  padding: 0px 12px;
  transform: translate3d(calc(var(--xOffset) - 50%), 0px, 0px);

  &--error {
    border: 2px solid $color-active;

    .bb-toast__text__heading {
      color: $color-active;
    }
  }

  &--success {
    border: 2px solid $color-success;

    .bb-toast__text__heading {
      color: $color-success;
    }
  }

  &-appear,
  &-enter {
    transform: translate(-50%, $translateY);
  }

  &-appear-active,
  &-enter-active {
    transform: translate(-50%, 0);
    transition: transform 150ms ease-in;
  }

  &-exit {
    transform: translate(calc(var(--xOffset) - 50%), $translateY);
    transition: transform 150ms ease-in;
  }

  &-exit-active {
    transform: translate(calc(var(--xOffset) - 50%), $translateY);
    transition: transform 150ms ease-in;
  }

  > div {
    margin: 0;
    display: flex;
    color: inherit;
    width: 100%;
  }

  &__icon {
    margin-right: 18px;
    display: flex;
  }

  &__text {
    @include flex(column, center, flex-start);
    @include font(14px, 18px, 500);

    width: 100%;
    margin-left: 12px;

    > p {
      margin: 0px;
    }

    &__description {
      color: $color-text-primary;
    }
  }

  &__close {
    @include button-reset;
    @include size(24px);

    margin-left: 40px;
  }

  @include below-responsive-height {
    padding: 0 10px 0 16px;

    &__text {
      font-size: 12px !important;
    }
  }
}
