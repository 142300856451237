@use '~scss/settings' as *;

.denied-title {
  @include font(14px, 24px, 600);

  padding: 20px;
  max-width: 540px;
  text-align: start;
  color: $color-text-primary;

  a {
    text-decoration: none;
    color: $color-button-primary;
  }
}

.settings-list {
  padding: 0 20px;
}
