@use '~scss/settings' as *;

.bb-referral-code-form {
  @extend %modal-grid;

  &__content {
    p {
      @include flex(row, center, center);
      @include font(12px, 16px, 600);

      width: 100%;
      margin: 0;
      padding: 8px 12px;
      letter-spacing: 0.5px;
      border-radius: $border-radius-standard;
      color: $color-text-primary;
      background-color: $color-player-card-background;

      &:not([class*='embedded']) {
        margin-bottom: 24px;
      }
    }
  }

  &__embedded[class] {
    margin-top: 12px;
  }

  &__footer {
    padding: 16px 0px;

    > button {
      width: 100%;
    }
  }
}
