%top-cheese {
  --font-size: 14px;
  --line-height: 16px;
  --border-radius: 8px;
  --font-family: Inter;

  --color-button-primary-values: 228, 88, 38;
  --color-button-primary: rgb(var(--color-button-primary-values));
  --color-active: #e45826;
  --color-active-secondary: #e45826;
  --color-background-primary: #faf8f5;
  --color-background-secondary: #fcfbfa;
  --color-text-primary: #1b1a17;
  --color-text-secondary: #a39989;
  --color-title-text: #1b1a17;
  --color-header: #1b1a17;
  --color-text-flags: #1b1a17;
  --color-unique-button: #f0a501;
  --color-bet-status: #a39989;
  --color-sport-icon: 1b1a17;
  --color-settings-options: #f0eae1;
  --color-modal: #faf8f5;
  --color-disabled: #a39989;
  --color-amount-slider-line: #1b1a17;
  --color-bet-cards: #a39989;
  --color-selected-option-background: #faf8f5;
  --color-player-card-background: #f5f1eb;
  --color-button-text: #fff;

  --color-cards-background: #f0eae1;
  --color-radio-slider: #a39989;
  --color-rang: #a39989;

  --circle-box-shadow: unset;
}
