@use '~scss/settings' as *;

.datepicker-menu-container {
  @media (max-width: 525px) {
    header {
      @include flex(column);

      > :first-child {
        border-bottom: none;
      }
    }
  }

  @media (max-width: 340px) {
    &__main {
      grid-template-columns: repeat(7, 1fr);
    }
  }
}
