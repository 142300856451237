@use '~scss/settings' as *;

.bb-settings-card-field {
  @include flex(column, center, center);
  width: 100%;

  > label {
    @include size(100%);
  }

  &--error {
    margin: 0px 2px;
    > .bb-settings-card {
      border: 2px solid $color-reddish;
      box-shadow: $reddish-box-shadow;
    }
  }

  > small {
    color: $color-reddish;
    font-size: 10px;
    margin-top: 8px;
  }
}
