@use '~scss/settings' as *;

.bb-chat {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  overflow: auto;
  margin: 12px 8px 0;

  &::-webkit-scrollbar {
    display: none;
  }

  > div:nth-child(1) {
    @include flex(row, center, center);

    width: 100%;

    .ncoded-button {
      @include size(36px);

      @extend %primary-button;
      background-color: transparent;
      border-radius: $border-radius-round;
      min-width: 36px;
      color: $color-text-secondary;
    }

    .ncoded-input {
      @extend %input-rounded;
      @include size(100%, 36px);

      padding: 0px 8px;
      border: 0px;
      background-color: $color-search;

      &:focus-within {
        border: 0px;
      }

      > input::placeholder {
        color: $color-disabled;
      }
    }
  }

  > div:nth-child(2) {
    @include flex(column, flex-start center);
    @include size(100%);
    @include border-top-radius($border-radius-standard);
  }
}

.portal {
  .dropdown__content {
    @include flex(column, center, flex-start);

    min-width: 184px;
    background-color: $color-modal;
    padding: 12px;
    margin-top: 6px;

    position: fixed;

    > .ncoded-button {
      @include flex(row, flex-start, center);

      height: 24px;
      width: 100%;
      color: $color-text-primary;
      margin-bottom: 12px;
      background-color: transparent;

      &:last-child {
        margin-bottom: 0px;
      }

      > svg {
        @include size(24px);

        color: $color-text-secondary;
        margin-right: 12px;
      }
    }
  }
}
