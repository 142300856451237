@use '~scss/settings' as *;

.bb-appear-animation {
  animation: var(--bb-animation);

  &--appear {
    opacity: 0;
  }

  &--move-in {
    transform: translate3d(0, 100%, 0);
  }

  &--move-left {
    transform: translate3d(100%, 0, 0);
  }

  &--move-right {
    transform: translate3d(100%, 0, 0);
  }

  &--move-down {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveLeft {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveRight {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveIn {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes moveDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
