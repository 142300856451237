@use '~scss/settings' as *;

.bb-settings {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: auto;
  padding: 0px 8px;
  width: 100%;
  margin-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__options {
    @include flex(column, center, center);
    @include flex-gap(8px, column);

    width: 100%;
    padding: 12px 0;
  }

  &__header {
    @extend %menu-header;
  }
}
