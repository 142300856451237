@use '~scss/settings' as *;

.bb-stadium {
  grid-area: content;
  scroll-behavior: smooth;
  overflow: auto;

  &__content {
    @include flex(column, flex-start, center);

    background-color: $color-background-primary;

    &__heading {
      @include font(20px, 24px, 800);

      align-self: flex-start;
      color: $color-text-primary !important;
      margin: 0;
      padding-left: 16px;
    }

    &__search {
      @include flex(row);

      width: 100%;
      padding: 0 16px 16px;

      > .ncoded-input {
        @extend %input-rounded;
        @include size(100%, 36px);

        padding: 0px 8px;
        border: 0px;
        background-color: $color-search;
        margin-right: 12px;

        > input::placeholder {
          color: $color-disabled;
        }

        &:focus-within {
          border: 0px;
        }
      }

      > .ncoded-button {
        @extend %action-round-button;

        color: $color-white;
      }
    }

    &__image-slider {
      padding: 0 0 24px;
      background-color: $color-background-primary;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__radio-slider {
      width: 100%;
      background-color: $color-background-primary;
      padding: 24px 16px;

      > .bb-radio-slider {
        @extend %slider-primary;
        @extend %slider--sports-list;

        margin: unset;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
