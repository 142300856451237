@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools//t-flex' as *;
@use './tools/t-font-size' as *;

@mixin confirm-modal {
  @extend %betbears-modal;
  @include flex(column, flex-start, center);
  @include size(90%, fit-content);

  min-height: 264px;
  border-radius: $border-radius-standard;

  > header {
    padding: 20px 16px;

    > p {
      @include font(12px, 16px, 600);

      margin: 0px;
      letter-spacing: 0.5px;
      color: $color-text-primary;
      text-transform: uppercase;
    }
  }

  > main {
    @include flex(row, center, center);
    @include size(100%);

    padding: 16px;
    flex: 1;

    > p,
    > div {
      @include font(14px, 16px, 600);

      margin: 0px;
      text-align: center;
      letter-spacing: 0.5px;
      color: $color-text-primary;
    }
  }

  > footer {
    @include flex(row, center, center);
    @include size(100%, 60px);
  }
}

@mixin footer-modal {
  @include flex(row, space-between, center);

  margin-top: 0;
  width: 100%;
  z-index: 1;

  > .ncoded-button {
    min-width: 0px;
    flex: 1;
    height: 44px;

    &:first-child {
      margin-right: 12px;
    }

    &--secondary {
      @extend %secondary-border-button;
    }
  }
}
