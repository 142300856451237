@use '~scss/settings' as *;

.bb-driver-details {
  @extend %modal-grid;

  &__container {
    @include flex(column, center, center);
    width: 100%;

    position: relative;
    border-radius: 8px;
    margin-bottom: 22px;
    background-color: $color-background-primary;

    &__content {
      @include flex(row, center, center);

      width: 100%;

      > .ncoded-button {
        @include border-bottom-radius(8px);
        @include size(100%);

        position: relative;
        font-weight: normal;
        font-size: 12px;
        height: 44px;
        border: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        background-color: $color-player-card-background;

        > svg {
          color: $color-text-secondary;
        }

        > label {
          @include size(100%);
          position: absolute;
        }

        &:nth-child(1) {
          border-bottom-right-radius: 0px;
          color: $color-text-secondary;
          font-weight: 700;
          font-size: 10px;
          letter-spacing: 0.5px;
        }

        &:nth-child(2) {
          border-bottom-left-radius: 0px;
          color: $color-text-secondary;
          font-weight: 700;
          font-size: 10px;
          letter-spacing: 0.5px;
        }
      }
    }

    > div:nth-child(1) {
      padding: 32px 0px;

      > img {
        @include flex(row, center, center);
        @include size(100%, 200px);

        max-height: 100%;
        position: relative;
        object-fit: contain;
      }
    }

    > small {
      position: absolute;
      bottom: 44px;
      margin: 0px 12px;
      left: 0;
      color: $color-error;
    }
  }

  &__footer {
    @include flex(row, center, center);

    padding: 16px 0px;
    > button {
      width: 100%;
    }
  }
}
