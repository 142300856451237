@use '~scss/settings' as *;

.bb-onboarding-slide {
  text-align: center;
  padding: 0 20px;
  color: $color-text-primary;

  &__heading {
    @include font(22px, 18px, 700);

    padding-bottom: 16px;
  }

  &__text {
    @include font(16px, 26px, 400);

    opacity: 0.6;
    padding-bottom: 10px;
  }

  @include mobile {
    &__heading {
      @include font(18px, 24px, 700);

      padding-bottom: 16px;
    }

    &__text {
      @include font(14px, 20px, 400);

      padding-bottom: 10px;
    }
  }
}
