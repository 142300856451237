@use '~scss/settings' as *;

.bb-team-filter-modal {
  @include flex(row, center, flex-end);

  &__container {
    display: block;
    overflow: auto;
    width: 100%;
    padding: 16px 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    > p {
      margin: 0px;
      color: $color-text-secondary;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }

    > .bb-numeric-field {
      margin-bottom: 16px;
      > .bb-numeric {
        > input {
          background-color: transparent;
          color: $color-text-primary;
        }
      }
    }

    .bb-radio-slider:nth-child(2) {
      overflow: auto;
      margin: 0px;
      padding: 0px;
      margin-bottom: 16px;

      &::before {
        z-index: 1;
        box-shadow: none;
      }

      > label:first-of-type {
        @include flex(row, center, center);
        flex: 0 0 64px;

        color: $color-text-primary;
        font-size: 12px;
        border: 1px solid rgba($color-disabled, 0.5);
        border-radius: $border-radius-round;
      }

      > label {
        z-index: 1;
      }
    }
  }

  &__footer {
    @include flex(row, center, center);

    width: 100%;
    height: 68px;
    border-top: 1px solid $color-alabaster;
    padding: 12px;

    > button {
      width: 100%;
    }

    > button:first-of-type {
      margin-right: 12px;
      background-color: $color-not-active-button;
      opacity: 0.5;
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    height: fit-content;

    > header {
      background-color: $color-alabaster;

      > p {
        font-weight: 600;
        font-size: 12px;
        color: $color-text-primary;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    > main {
      height: 100%;

      > form {
        height: calc(100% - 112px);
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr;
        overflow: auto;
      }

      > p {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: $color-error;
        text-transform: capitalize;
      }
    }
  }
}
