@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-flex' as *;
@use './tools/t-position' as *;
@use './tools/t-font-size' as *;

%menu-header {
  @include flex(row, center, center);

  width: 100%;
  background-color: $color-alabaster;
  border-radius: $border-radius-standard;
  padding: 20px 16px;
  position: relative;

  > .ncoded-button:nth-child(1) {
    @include absolute(top 50% left 16px);

    min-width: 0px;
    transform: translate3d(0, -50%, 0);
    background-color: transparent;
    color: $color-text-secondary;
    height: 24px;

    > svg {
      @include size(24px);
      transform: rotate(180deg);
    }
  }

  > p {
    @include font(12px, 16px, 600);

    margin: 0px;
    width: 100%;
    color: $color-text-primary;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}
