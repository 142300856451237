@use '~scss/settings' as *;

.bb-notifications {
  @include flex(column, flex-start, center);

  width: 100%;
  overflow: auto;
  margin-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__options {
    @include absolute(top 50% right 16px);
    @include size(24px);

    transform: translate3d(0, -50%, 0);

    .ncoded-button {
      @include size(30px);
      @include absolute(top -2px left 0);

      min-width: 0px;
      background-color: transparent;
      color: $color-text-secondary;
    }
  }

  &__container {
    @include size(100%);

    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    > .bb-infinite-scroll {
      width: 100%;

      > div:first-child {
        @include border-top-radius($border-radius-standard);
      }
    }

    .loader-container {
      @extend %relative-loader;
    }
  }

  &__header {
    @extend %menu-header;

    margin: 0 8px;
    width: auto;
  }
}
