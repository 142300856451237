@use '~scss/settings' as *;

.bb-confirm-popup {
  background: $color-not-active-button;
  animation: none;

  &__form-actions {
    @include footer-modal;

    .ncoded-button:first-child {
      background-color: $color-not-active-button;
      color: $color-text-primary;
      border: none;
    }
  }

  > .overlay__content {
    @include confirm-modal;

    position: relative;

    > main {
      @include flex(column, center, center);
      @include size(100%);

      > div {
        @include flex(column);
        @include size(100%);

        position: relative;
        flex: 1;

        > p {
          @include font(12px, 16px, 600);

          width: 100%;
          margin: auto 0;
        }
      }
    }
  }
}
