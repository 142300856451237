@use '~scss/settings' as *;

.bb-image-slider {
  @include size(100%, auto);
  @include flex(column, flex-start, center);

  --padding: 0px;

  padding: 0 var(--padding);
  box-sizing: content-box;
  position: relative;
  overflow: hidden;

  &__image-container {
    @include flex(row, center, center);
    @include size(100%, 240px);

    margin-bottom: 12px;
    margin-left: calc(-1 * var(--padding));
    position: relative;
    transform: translate3d(var(--translateOffset), 0px, 0px);

    &.transition {
      transition: transform 0.2s ease-in-out;
      margin-left: 0px;
    }

    &__image {
      @include flex(row, center, center);
      @include absolute(top 0 left 0);
      padding: 0 var(--padding);
      box-sizing: content-box;

      &,
      > img {
        @include size(100%);
      }

      > img {
        object-fit: cover;
        pointer-events: none;
      }

      &:after {
        @include absolute(top 0 bottom 0);

        left: var(--padding);
        right: var(--padding);
        content: '';
        opacity: 0.6;
        border-radius: $border-radius-standard;
        background-blend-mode: normal, color;
      }
    }
  }

  &__dot-container {
    @include inline-flex(row, center, center);
    @include size(auto, 16px);

    position: relative;

    &__dot {
      @include size(4px);

      background-color: $color-text-secondary;
      border-radius: 50%;

      + .bb-image-slider__dot-container__dot {
        margin-left: 8px;
      }
    }
  }

  &__slide-dot {
    @include size(1px, 40px);
    @include absolute(left 2px);

    transform-origin: left;
    --compress: calc(1 / var(--expand));

    transform: translate3d(var(--xOffset), 0, 0) scale3d(0.1, 0.1, 0.1);

    > div {
      @include size(100%);

      transform: scale3d(var(--expand), 1, 1);
      transform-origin: var(--dotTransformOrigin, left);
      background-color: $color-text-primary;
      position: relative;

      &.transition-transform {
        transition: transform 0.2s ease-in-out;
      }

      &::before,
      &::after {
        @include size(40px);
        @include absolute(top 0);

        border-radius: 50%;
        display: block;
        background-color: inherit;
        content: '';
        transform: scale3d(var(--compress), 1, 1);
      }

      &::before {
        left: -20px;
        background: linear-gradient(
          to right,
          $color-text-primary 50%,
          transparent 50%
        );
      }

      &::after {
        right: -20px;
        background: linear-gradient(
          to right,
          transparent 50%,
          $color-text-primary 50%
        );
      }
    }
  }
}
