@use '~scss/settings' as *;

.bb-radio-slider {
  @include flex(row, flex-start, center);
  color: $color-sport-icon;

  gap: 8px;
  width: 100%;
  position: relative;
  margin: -35px 0px;
  padding: 50px 0px;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    @include flex(row, center, center);

    height: 36px;
    transform-origin: left;
    content: '';
    background-color: $color-active;
    position: absolute;
    transform: translate3d(var(--xOffset), 0, 0);
    transition: transform 0.4s ease-in-out, width 0.4s ease-in-out;
    z-index: -1;
    width: var(--width);
    border-radius: $border-radius-round;
  }

  &__all {
    @include flex(row, center, center);

    z-index: -1;
    border-radius: $border-radius-standard;
  }

  > label {
    @include flex(row, center, center);

    cursor: pointer;
    flex: 0 0 36px;
    transition: opacity 0.3s ease-in-out;
    text-align: center;
    white-space: pre;
  }

  & > &__selected {
    opacity: 1;
    color: $color-white;
  }
}
