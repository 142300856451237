@use '~scss/settings' as *;

.bb-numeric {
  @include flex(row, space-between, center);

  width: 100%;
  background: transparent;
  background-color: $color-player-card-background;
  border-radius: $border-radius-standard;
  min-width: 90px;
  padding: 6px 12px;

  &--disabled {
    opacity: 0.5;
  }

  > input {
    @include size(100%);

    font-size: 14px;
    border: none;
    outline: none;
    text-align: center;

    &:disabled {
      background-color: transparent;
      cursor: inherit;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ncoded-button {
    @include size(24px);

    background: transparent;
    color: $color-text-secondary;
    min-width: 0px;

    > svg {
      margin: 0px;
    }

    > button {
      min-width: 0px;
    }
  }

  &--secondary-style-type {
    width: 100%;
    position: relative;
    padding: 0;
    background-color: $color-background-primary;
    gap: 8px;

    > input {
      @include font(48px, 48px, 700);

      order: 1;
      background-color: transparent;
      color: $color-text-primary;
      text-align: start;
    }

    .ncoded-button {
      @include size(40px);

      background-color: $color-active;
      color: $color-text-primary;
      border-radius: 50%;
      min-width: 40px;

      &:first-child {
        background-color: $color-player-card-background;
        order: 2;
      }

      &:last-child {
        background-color: $color-active;
        order: 2;
      }
    }
  }
}
