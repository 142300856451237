@use '~scss/settings' as *;

.bb-sport-category {
  @include flex(column, center, center);

  height: 124px;
  cursor: pointer;
  color: $color-text-primary;
  border-radius: $border-radius-standard;

  > svg {
    @include size(32px);
    margin-bottom: 32px;
  }

  > label {
    @include font(12px, 16px, 600);

    letter-spacing: 0.5px;
    text-transform: capitalize;
    text-align: center;
  }

  &--active {
    background-color: $color-background-secondary;
    > label {
      color: $color-text-primary;
      font-weight: 700;
      transition: font-weight 0.3s ease-in-out;
    }
  }
}
