@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-flex' as *;

%bet-status {
  @include flex(row, center, center);

  position: absolute;
  top: 12px;
  right: 12px;

  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: transparent;

  > svg {
    @include size(16px);
    margin-right: 8px;
    color: $color-text-secondary;
  }

  &--open {
    background-color: $color-background-primary;
  }

  &--close {
    background-color: $color-reddish;
  }

  &--win {
    background-color: $color-button-primary;
    > svg {
      @include size(16px);
      margin-right: 8px;
      color: $color-button-primary;
    }
  }

  &--lose {
    background-color: $color-reddish;

    > p {
      color: $color-error;
    }

    > svg {
      @include size(16px);
      margin-right: 8px;
      color: $color-reddish;
    }
  }
}
