@use '~scss/settings' as *;

.bb-profile-settings {
  display: grid;
  padding: 24px 8px;
  overflow-y: auto;
  grid-template-rows: auto 1fr auto;

  &__title {
    @include font(20px, 24px, 800);

    padding: 0 16px;
    margin: 0 0 12px;
  }

  &__user-image {
    @include size(160px);

    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 24px;

    img {
      @include size(100%);
    }

    svg {
      @include size(40px);
      @include absolute(bottom 0 right 0);

      text-decoration: inherit;
      color: $color-text-secondary;
    }
  }

  &__content {
    @include flex-gap(8px, column);
    @include size(100%, fit-content);

    padding: 16px;
    border-radius: 8px;
    place-content: flex-end;
    border: 1px solid $color-alabaster;

    .ncoded-button[type='submit'] {
      width: 100%;
      margin-top: 12px;
    }
  }
}
