@use '~scss/settings' as *;

.bb-group-chat-modal {
  top: $modal-top;

  &__header {
    @include flex(row, center, flex-start);

    width: 100%;
    margin-top: 4px;
    margin-bottom: 16px;

    > div:nth-child(1) {
      width: 20%;
      margin-right: 16px;

      .ncoded-button:first-of-type {
        @include size(64px);
        @extend %primary-button;

        position: relative;
        background-color: $color-player-card-background;
        border-radius: 50%;
        min-width: 0px;

        > svg {
          @include size(24px);
        }

        > label {
          @include size(100%);

          position: absolute;
        }
      }

      > img {
        @include size(64px);

        object-fit: cover;
        border-radius: 50%;
      }
    }

    > div:nth-child(2) {
      width: 80%;

      > p {
        @include font(10px, 16px, 700);

        margin: 0 0 4px;
        color: $color-text-secondary;
        text-transform: uppercase;
      }

      .ncoded-input {
        @include size(100%, 36px);

        color: $color-text-secondary;
        border: 1px solid $color-alabaster;

        > input::placeholder {
          color: $color-disabled;
        }
      }
    }
  }

  &__container {
    grid-area: modal-main;
    overflow: auto;
    width: 100%;
    padding: 0px 8px;
    margin-top: 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    hr {
      @include size(100%, 1px);

      border: none;
      margin-bottom: 16px;
      background-color: $color-alabaster;
    }

    > .ncoded-input {
      @extend %input-rounded;
      @include size(100%, 36px);

      border: 0px;
      padding: 0px 8px;
      margin: 0px 4px;
      color: $color-text-secondary;
      background-color: $color-search;

      &:focus-within {
        border: 0px;
      }

      > svg {
        @include size(24px);

        fill: $color-text-primary;
      }

      > input::placeholder {
        color: $color-disabled;
      }
    }
  }

  &__footer {
    @extend %modal-footer;
  }

  &__friend {
    @include flex(row, center, center);

    width: 100%;

    > div:nth-child(1) {
      @include flex(row, flex-start, center);
      @include size(100%, 64px);

      padding: 0px 4px;

      > img {
        @include size(40px);

        object-fit: cover;
        border-radius: 50%;
      }

      > p {
        @include font(14px, 20px, 700);

        color: $color-text-primary;
        margin: 0px;
        margin-left: 12px;
        letter-spacing: 0.5px;
      }
    }

    > div:nth-child(2) {
      .ncoded-button {
        @include size(24px);

        border-radius: 50%;
        background-color: transparent;
        border: 1px solid $color-disabled;
        min-width: 0px;

        > svg {
          display: none;
        }
      }
    }

    &--active {
      > div:nth-child(2) {
        .ncoded-button {
          > svg {
            display: flex;
            color: $color-active;
            margin: 0px;
          }
        }
      }
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      > p {
        @include font(12px, 16px, 600);

        color: $color-text-primary;
        text-transform: uppercase;
      }
    }

    > main {
      height: 100%;

      > form {
        @extend %modal-grid;
      }

      > p {
        @include font(12px, 16px, 600);

        text-align: center;
        color: $color-error;
        text-transform: capitalize;
      }
    }
  }
}
