@use '~scss/settings' as *;

.bb-animated-radio {
  @include flex(column);

  width: 100%;
  overflow: hidden;

  &__radio-slider {
    @extend %slider-secondary;

    flex: 0 0 auto;
  }

  &__content {
    position: relative;

    overflow: hidden;
  }

  &__component {
    &--left {
      &.enter {
        transform: translate3d(-100%, 0, 0);
      }

      &.enter-active {
        transform: translate3d(0%, 0, 0);
      }

      &.exit-active {
        transform: translate3d(100%, 0, 0);
      }
    }

    &--right {
      &.enter {
        transform: translate3d(100%, 0, 0);
      }

      &.enter-active {
        transform: translate3d(0%, 0, 0);
      }

      &.exit-active {
        transform: translate3d(-100%, 0, 0);
      }
    }

    &--left,
    &--right {
      &.enter-active,
      &.exit-active {
        transition: transform 400ms $easeInOutCubic;
      }

      &.exit-active {
        @include absolute(top 0);
        @include size(100%);
      }
    }
  }
}
