@mixin ul-reset() {
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
}

@mixin button-reset {
  display: inline-block;
  outline: none;
  border: none;
  font: inherit;
  background-color: transparent;
  color: inherit;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

@mixin input-reset {
  border: none;

  > input {
    border: none;
    padding: 0;
  }
}
