@use '~scss/settings' as *;

.bb-animation {
  animation: var(--bb-appear-animation);

  &--exit,
  &-exit-active {
    animation: var(--bb-disappear-animation);
  }

  &--move-up {
    transform: translate3d(0, 100%, 0);
  }

  &--move-down {
    transform: translate3d(0, 0, 0);
  }

  &--move-left {
    transform: translate3d(100%, 0, 0);
  }

  &--move-right {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes moveLeft {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveRight {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveUp {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes moveDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes moveOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0 0);
  }
}

@keyframes backgroundColor {
  from {
    background-color: transparent;
  }
  to {
    background-color: $color-text-primary;
  }
}

@keyframes backgroundColorReverse {
  from {
    background-color: $color-text-primary;
  }
  to {
    background-color: transparent;
  }
}
