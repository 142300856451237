@use '~scss/settings' as *;

.bb-feed {
  @include flex(column, flex-start, center);

  width: 100%;
  padding: 12px 0;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    @include flex(row, center, center);

    width: 100%;
    gap: 8px;
    padding: 4px 16px 12px;
    border-bottom: 1px solid $color-alabaster;

    > .bb-radio-slider {
      @extend %slider-rect;

      height: 36px;
      margin-bottom: 0;

      &::before {
        border-radius: 40px;
      }

      > label {
        @include font(14px, 16px, 600);

        height: 100%;
        border-radius: 40px;
      }
    }

    > .ncoded-button {
      @extend %action-round-button;

      > svg {
        margin: 0;
      }
    }
  }

  &__container {
    width: 100%;
  }

  &__content {
    @include size(100%, auto);

    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;
    padding: 0px 8px 8px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
