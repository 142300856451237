@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: normal;
  src: url(/assets/font/AvenirNextLTPro-Regular.otf);
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: bold;
  src: url(/assets/font/AvenirNextLTPro-Bold.otf);
}

@font-face {
  font-family: 'AvenirNext';
  font-style: italic;
  font-weight: normal;
  src: url(/assets/font/AvenirNextLTPro-It.otf);
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 600;
  src: url(/assets/font/AvenirNextLTPro-Demi.otf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url(/assets/font/Inter-Thin.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url(/assets/font/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/font/Inter-Light.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: url(/assets/font/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(/assets/font/Inter-Medium.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(/assets/font/Inter-SemiBold.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  src: url(/assets/font/Inter-Bold.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url(/assets/font/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url(/assets/font/Inter-Black.ttf);
}
