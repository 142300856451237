@use '~scss/settings' as *;

.bb-feed-card {
  @include flex(column, center, center);

  width: 100%;

  &__content {
    @include flex(column, center, center);

    width: 100%;

    &__teams {
      display: flex;
      width: 100%;
      gap: 8px;
      padding: 12px;
      border-bottom: 1px solid $color-alabaster;

      .team.bb-bet-team {
        flex: 1 0 0;
        padding: 20px 26px 16px;

        > img {
          @include size(56px);
          margin-bottom: 8px;
        }
      }

      .first-team {
        .bb-bet-team__betting-odd {
          left: 8px;
        }
      }

      .second-team {
        .bb-bet-team__betting-odd {
          right: 8px;
        }
      }
    }

    &__user-list {
      border-bottom: 1px solid $color-alabaster;
    }
  }

  &__footer {
    @include flex(row, space-between, center);

    width: 100%;
    gap: 8px;
    padding: 12px 12px 24px;
    border-bottom: 1px solid $color-alabaster;
  }
}
