@use '~scss/settings' as *;
.bb-calendar {
  @media (min-width: 776px) and (max-width: 1350px) {
    > main {
      grid-template-columns: repeat(2, 1fr);

      > section:last-child {
        grid-column: span 2;
      }
    }
  }

  @media (max-width: 775px) {
    > main {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 550px) {
    padding: 10px;
  }
}
