@use '~scss/settings' as *;

.bb-pin-field {
  @include flex(column, center, center);

  &__content {
    @include flex(row, center, center);
    margin: 16px 0px;

    > input {
      @include flex(row, center, center);
      @include size(48px, 64px);
      @include font(24px, 20px, 500);

      font-weight: bold;
      font-family: inherit;
      text-align: center;
      border-radius: $border-radius-standard;
      color: $color-text-primary;
      background-color: $color-player-card-background;
      border: 0px;
      margin-right: 10px;
      outline: none;

      &:focus-within {
        background-color: $color-radio-slider;
      }
    }
  }

  > p {
    color: $color-reddish;
    font-size: 14px;
    text-align: center;
  }
}
