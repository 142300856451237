@use '~scss/settings' as *;

.bb-referral-code {
  background: $color-not-active-button;
  animation: none;

  &__container {
    @include flex(column, center, center);
    @include size(100%);

    gap: 20px;
    margin-bottom: 12px;

    &__info {
      @include flex(row, center, center);
      @include font(12px, 16px, 600);

      width: 100%;
      margin: 0;
      padding: 8px 12px;
      text-align: center;
      letter-spacing: 0.5px;
      border-radius: $border-radius-standard;
      color: $color-text-primary;
      background-color: $color-player-card-background;
    }

    > .ncoded-input {
      min-height: 56px;
    }
  }

  &__form-actions {
    @include footer-modal;

    .ncoded-button:first-child {
      background-color: $color-not-active-button;
      border: none;
    }
  }

  > .overlay__content {
    @include confirm-modal;

    > main {
      > form {
        @include flex(column, flex-start, flex-start);
        @include size(100%);
      }
    }
  }
}
