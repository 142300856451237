@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;

@mixin input {
  position: relative;
  border: 1px solid $color-disabled;

  &:focus-within {
    border: 1px solid $color-text-secondary;
    box-shadow: none;
  }

  > input {
    background: transparent;
    color: $color-text-primary;
  }
}

%input-standard {
  @include input;
  border-radius: $border-radius-standard;
}

%input-rounded {
  @include input;
  border-radius: $border-radius-round;
}

@mixin input-style {
  .ncoded-input {
    @extend %input-standard;

    padding: 0;

    &--has-prefix {
      padding: 0px 8px;

      > svg {
        @include size(24px);
        margin-left: 8px;
      }
    }

    > input {
      padding: 10px;

      &::placeholder {
        color: $color-disabled;
      }

      &:-webkit-autofill {
        box-shadow: 0 0 0 30px inset
          rgba(var(--color-button-primary-values), 15%);
      }
    }

    &:not(.ncoded-input--error):focus-within {
      border-color: $color-rang;
    }

    &--error {
      background-color: transparent;
      border-color: $color-reddish;
    }
  }
}
