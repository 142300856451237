@use '~scss/settings' as *;

.bb-reset-password-modal {
  top: $modal-top;

  &__text {
    @include font(14px, 20px, 600);

    color: $color-text-primary;
    text-align: center;
    margin: 0;
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      > p {
        @include font(12px, 16px, 700);

        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $color-text-primary;
      }
    }

    > main {
      padding: 22px;

      > form {
        @include flex-gap(16px, column-reverse);

        > .ncoded-button {
          width: 100%;
        }
      }
    }
  }
}
