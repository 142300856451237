@use '~scss/settings' as *;

.bb-address-details {
  @extend %modal-grid;

  &__content {
    @include flex(column);

    overflow: auto;

    div:not(:first-of-type) {
      margin-top: 16px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    @include flex(row, center, center);

    padding: 16px 0px;
    > button {
      width: 100%;
    }
  }
}
