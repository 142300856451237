@use '~scss/settings' as *;

.bb-show-menu {
  @include flex(row);
  @include flex-gap(4px, row);

  width: 100%;
  padding: 0 10px;

  &__active {
    background-color: $color-active !important;
    color: $color-white !important;
    opacity: 1 !important;
  }

  .ncoded-button {
    color: $color-text-primary;
    box-shadow: none;
    padding: 8px;
    border-radius: $border-radius-standard;
    flex: 60px;
    background-color: $color-not-active-button;
    transition: background 150ms ease-in-out;
    min-width: 0px;
  }
}
