@use '~scss/settings' as *;

.bb-calendar-field {
  @include flex(column, center, center);

  width: 100%;
  margin-bottom: 12px;

  .bb-calendar {
    padding: 10px 0;
    width: 100%;
  }

  &__label {
    @include font(10px, 12px, 700);

    color: $color-rang;
    margin: 0 0 4px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: 100%;
    text-align: flex-start;
    padding: 0 24px;
  }

  hr {
    @include size(100%, 1px);

    background: $color-disabled !important;
    margin: 0px;
    border: 0px;
  }

  &__error {
    width: 100%;
    font-size: 12px;
    color: $color-error;
    text-align: center;
  }
}
