@use '~scss/settings' as *;

.bb-show-menu {
  @media (max-width: 525px) {
    button.ncoded-button {
      flex: 1 1 100%;
    }
  }

  @media (max-width: 340px) {
    .ncoded-button {
      max-width: unset;
    }
  }
}
