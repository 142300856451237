@use '~scss/settings' as *;

.bb-welcome-wizard {
  @include flex(row, center, flex-end);
  top: $modal-top;

  &.modal {
    z-index: 2;
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      border-bottom: 1px solid $color-alabaster;

      > p {
        @include font(12px, 16px, 700);

        color: $color-text-primary;
        text-transform: uppercase;
      }
    }

    > main {
      height: calc(100% - 195px);

      .ncoded-image-slider {
        height: 100%;

        &__slide-container__slide {
          flex-direction: column;
          justify-content: flex-start;
          overflow-y: scroll;

          &::after {
            display: none;
          }

          > img {
            @include size(300px, 600px);

            object-fit: contain;
            max-height: 600px;
            max-width: 300px;
            margin: 10px 0 40px;
          }

          &:first-of-type,
          &:last-of-type {
            justify-content: center;

            > img {
              @include size(unset);

              margin: 8px 0;
            }
          }
        }

        &__dot-container {
          margin-bottom: 10px;

          &__dot {
            @include size(8px);

            &--active {
              background-color: $color-text-primary;
            }
          }
        }
      }
    }
  }

  &__footer {
    @include flex(row, center, center);
    @include flex-gap(12px, row);
    @include size(100%, auto);
    @extend %modal-footer;

    border-top: 1px solid $color-alabaster;
    padding: 12px 12px 18px;

    .ncoded-button.bb-welcome-wizard__skip-button {
      color: $color-text-primary;
      background-color: $color-player-card-background;
    }
  }

  .ncoded-button.bb-welcome-wizard__share-button {
    padding: 6px;
    text-transform: uppercase;

    svg {
      @include size(14px, 16px);
    }
  }
}
