@use '~scss/settings' as *;

.bb-user-list {
  @include flex(column, center, center);

  width: 100%;
  padding: 12px;

  > div:nth-child(1) {
    @include flex(row, space-between, center);

    width: 100%;
    padding: 0 0 12px 4px;

    > p {
      @include font(10px, 16px, 600);

      margin: 0px;
      letter-spacing: 0.5px;
      color: $color-text-secondary;
      text-transform: uppercase;
    }
  }

  > div:nth-child(2) {
    @include flex(row, flex-start, center);

    gap: 8px;
    position: relative;
    overflow: auto;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .bb-user-image {
      > img {
        @include size(48px);
      }
    }
  }
}
