@use '~scss/settings' as *;

.bb-player-card {
  @include flex(column, center, center);
  @include font(14px, 16px, 600);

  position: relative;
  padding: 16px;
  background-color: $color-player-card-background;
  border-radius: $border-radius-standard;

  > img {
    @include size(100%, 160px);

    object-fit: cover;
    margin-bottom: 16px;
  }

  &__player-info {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-self: flex-start;
    width: 100%;
    padding-bottom: 20px;

    > p {
      margin: 0px;

      &:nth-child(1) {
        color: $color-text-primary;
        margin-bottom: 4px;
        width: 0;
        overflow: visible;
      }

      &:nth-child(2) {
        color: $color-active;
      }

      &:nth-child(3) {
        color: $color-text-secondary;
        font-size: 10px;
      }
    }
  }
}
