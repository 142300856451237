@use '~scss/settings' as *;

.ncoded-button.bb-onboarding-tutorial-option {
  @include size(100%, 48px);

  justify-content: flex-start;
  padding: 0 10px;
  color: $color-text-primary;
  background-color: $color-player-card-background;
}
