@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-size' as *;

%standard-range-slider {
  .ncoded-drag-item {
    &__button {
      @include size(100%);
    }

    &__content {
      display: none;
    }
  }
}

@mixin radio-slider-style {
  .ncoded-range {
    @extend %standard-range-slider;

    &__track[class] {
      background-color: $color-active;
    }

    &__track-range[class] {
      background-color: $color-alabaster;
    }
  }
}
