@use '~scss/settings' as *;

.bb-numeric-field {
  @include flex(column, center, center);

  max-height: 36px;

  &--error {
    .bb-numeric {
      border-color: $color-error;
    }
  }

  &__error {
    width: 100%;
    text-align: center;
    margin-top: 6px;
    font-weight: 600;

    .bb-numeric {
      border-color: $color-error;
    }
  }
}
