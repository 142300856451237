@use '~scss/settings' as *;
@use 'scss/font' as *;
@use 'scss/variables' as *;

html,
body,
#root {
  @include size(100%);

  overflow: hidden;
}

html body {
  --font-size: 16px;
  --line-height: 18px;
  --border-radius: 8px;
  --font-family: AvenirNext;

  --color-button-primary-values: 38, 225, 150;
  --color-button-primary: rgb(var(--color-button-primary-values));
  --color-active: #06a7ff;
  --color-active-secondary: #24a7ff;
  --color-background-primary: #021b3d;
  --color-background-secondary: #021733;
  --color-text-primary: #fff;
  --color-text-secondary: #9faec1;
  --color-title-text: #e7e7e7;
  --color-header: #021b3d;
  --color-text-flags: #9faec1;
  --color-unique-button: rgb(var(--color-button-primary-values));
  --color-bet-status: #0f1929;
  --color-sport-icon: #{rgba($color-white, 0.5)};
  --color-settings-options: #0d2a52;
  --color-modal: #0d2a52;
  --color-disabled: #2d4566;
  --color-amount-slider-line: #627590;
  --color-bet-cards: #0c274d;
  --color-selected-option-background: #0c274d;
  --color-player-card-background: #0c274d;
  --color-button-text: #0d2a52;

  --color-cards-background: #062247;
  --color-radio-slider: #0d2a52;
  --color-rang: #627590;

  --circle-box-shadow: 0 4px 16px -4px rgba(36, 167, 255, 0.76);

  &.top-cheese {
    @extend %top-cheese;
  }
}

body {
  @extend %font;

  @include select-style;
  @include input-style;
  @include button-style;
  @include radio-slider-style;

  scroll-behavior: smooth;

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  margin: 0;
  overscroll-behavior: none;

  p {
    margin: 0;
  }

  .bb-infinite-scroll {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .loader-container {
    &--overlay {
      background-color: $color-background-primary;
      opacity: 0.6;
    }
    &__loader {
      border-top: 3px solid $color-text-secondary;
    }
  }
}

html,
body,
.overlay {
  &--elevated[class] {
    z-index: 3;
  }

  @media screen and (min-width: $body-breakpoint) {
    max-width: $body-breakpoint;
    margin: 0 auto;
  }
}

body:has([class*='social-share']) {
  .dropdown__overlay {
    animation: unset;
    background-color: $color-text-primary;
    opacity: 0.4;
  }
}

.bb-toast {
  @media screen and (min-width: $body-breakpoint) {
    max-width: calc($body-breakpoint - 24px);
    margin: 0 auto;
  }
}

.hidden {
  display: none !important;
}

.bb-form {
  &__error-msg {
    color: $color-error;
    margin: 0px;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.neon-button {
  @extend %primary-button;
  background-color: $color-active;
  box-shadow: $neon-box-shadow;
  border-radius: $border-radius-round;
}

.portal {
  > .dropdown__content {
    border: 1px solid $color-rang;
  }
}

.actions-button {
  @extend %action-round-button;
}
