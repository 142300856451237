@use '~scss/settings' as *;

.year-month-picker {
  display: grid;
  padding: 12px 0;
  gap: 4px;

  &__field {
    @include flex(row, center, center);
    @include font(14px, 16px, 600);

    border-radius: $border-radius-standard;
    color: $color-rang;
    padding: 12px;

    &--valid {
      cursor: pointer;
      color: $color-text-primary;

      &:hover {
        cursor: pointer;
        background: var(--color-background-primary);
        color: var(--color-button-primary);
      }
    }

    &--invalid {
      color: $color-rang;
      cursor: default;
      text-decoration: line-through;

      &.year-month-picker__field--different-month {
        color: $color-gray-99;
      }
    }

    &--selected {
      color: $color-text-primary;
      background-color: $color-active;
      box-shadow: $neon-box-shadow;

      &:hover {
        cursor: default;
        color: $color-text-primary;
        background-color: var(--color-button-primary);
      }
    }
  }
}
