@use '~scss/settings' as *;

.bb-fixture-details {
  @include flex-gap(8px, column);
  padding: 0px 8px 8px;

  &__content {
    @include flex(column, center, center);

    width: 100%;
    margin-top: 12px;

    &__header {
      @include flex(row, center, center);
      @include border-top-radius($border-radius-standard);
      @include size(100%, 56px);

      background-color: $color-player-card-background;
      position: relative;

      > .ncoded-button {
        @include absolute(left 0);

        min-width: 0px;
        background-color: transparent;
        margin-left: 16px;

        > svg {
          @include size(24px);

          transform: rotate(180deg);
          color: $color-text-secondary;
        }
      }

      > p {
        @include font(12px, 16px, 700);

        color: $color-text-primary;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }

    &__info {
      @include flex(column, flex-end, center);
      @include size(30%, 100%);

      padding: 24px 0px;

      > p {
        margin: 0px;
        text-align: center;
      }

      > p:first-child {
        @include font(10px, 14px, 700);

        color: $color-text-secondary;
        margin-bottom: 8px;
      }

      > p:nth-child(2) {
        @include font(16px, 20px, 700);

        color: $color-text-primary;
        margin-bottom: 32px;
      }

      > p:nth-child(3) {
        @include font(12px, 16px, 700);

        color: $color-text-primary;
        text-transform: capitalize;
        margin-bottom: 8px;
      }

      > p:nth-child(4) {
        @include font(12px, 16px, 700);

        color: $color-text-secondary;
        text-transform: capitalize;
      }
    }

    &__data {
      @include flex(column, center, center);
      @include border-bottom-radius($border-radius-standard);

      width: 100%;
      background-color: $color-player-card-background;
      border-top: 1px solic $color-alabaster;

      > button {
        width: 100%;
      }

      > div:nth-child(1) {
        @include flex(row, space-evenly, center);

        width: 100%;

        > .bb-bet-team {
          background-color: $color-background-primary;
          width: 30%;
          > img {
            @include size(68px);
          }
        }
      }
    }
  }

  &__select {
    @extend %slider-secondary;
  }

  &__footer {
    @include size(100%);
    @include border-bottom-radius($border-radius-standard);

    background-color: $color-player-card-background;
    padding: 16px;

    > .ncoded-button {
      width: 100%;
      margin-top: 24px;
    }

    &__content {
      @include flex(row, center, center);

      width: 100%;
      background-color: $color-player-card-background;

      > div {
        @include flex(column, center, center);
        flex: 1;

        > p:nth-child(1) {
          @include font(16px, 24px, 700);

          color: $color-text-primary;
          margin: 0px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }

        > p:nth-child(2) {
          @include font(10px, 16px, 600);

          color: $color-text-secondary;
          margin: 0px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
    }
  }
}
