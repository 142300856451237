@use '~scss/settings' as *;

.bb-outcome-card {
  @include flex(column, center, center);

  position: relative;
  width: 100%;
  padding: 8px;
  border: 1px solid $color-alabaster;
  border-radius: $border-radius-standard;
  margin-bottom: 18px;

  .bb-collapsible--open {
    .bb-collapsible__animation-end {
      overflow: visible !important;
    }

    .bb-collapsible__content {
      overflow: visible;
    }
  }

  &__collapsible {
    .bb-collapsible__trigger {
      width: 100%;
    }
  }

  &__subtype-warn,
  &__subtype-error {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  &__subtype-warn {
    color: $color-yellowish;
  }

  &__subtype-error {
    color: $color-reddish;
  }

  &__outcome {
    @include flex(row, center, center);

    width: 100%;

    &__team {
      @include flex(row, center, center);
      @include size(48px);

      &--active {
        border-radius: $border-radius-standard;
        border: solid 1px $color-active;
        box-shadow: $neon-box-shadow;
        background-color: $color-background-secondary;
      }

      > img {
        @include size(100%);

        object-fit: contain;
        padding: 4px;
      }
    }

    &__content {
      @include flex(column, flex-start, center);

      margin-bottom: 4px;
      flex: 1;

      > p {
        margin: 0px;
        width: 60%;
        text-align: center;
        color: $color-text-primary;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }
  }

  &__options {
    @include flex(column, center, center);

    width: 100%;
    padding: 12px 8px;

    &__handicap {
      @include flex(row, center, center);

      width: 100%;
      margin-bottom: 16px;
    }

    &__over-under {
      @include flex(row, center, center);

      width: 100%;
      margin-bottom: 16px;
    }

    div > .bb-radio-slider {
      overflow: auto;

      margin-bottom: 0px;
      margin-right: 8px;

      > label {
        @include flex(row, center, center);
        flex: 1 0 0;
      }

      &::before {
        flex: 1 0 0;
        width: 48%;
      }
    }

    .bb-input-field {
      padding: unset;
      flex: 1 0 33%;
      color: $color-text-secondary;

      .ncoded-input {
        @include size(100%, 36px);
        @include font(14px, 24px, 600);

        input {
          text-align: center;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    .bb-select {
      width: 100%;

      > legend {
        background-color: $color-cards-background;
        color: $color-rang;
      }
    }

    .bb-radio-slider {
      @extend %slider-rect;

      > label {
        transition-delay: 100ms;
        transition-property: color;
        color: $color-text-primary;
        padding: 10px 4px;
      }
    }
  }

  &__advanced {
    @include flex(row, space-between, center);
    @include absolute(bottom -10px);

    min-width: 120px;
    max-height: 20px;
    padding: 2px 4px;

    border-radius: $border-radius-round;
    border: 1px solid $color-alabaster;
    background-color: $color-cards-background;
    color: $color-rang;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    > div:first-of-type {
      @include size(12px);

      background-color: $color-background-secondary;
      border-radius: 50%;
    }

    > svg:nth-child(1) {
      @include size(12px);
      margin-right: 8px;
      opacity: 0.4;
      border-radius: 50%;
      background-color: $color-background-secondary;
      color: $color-background-secondary;
    }

    > svg:nth-child(2) {
      @include size(16px);

      transform: rotate(90deg);
    }

    &--active {
      border: 1px solid $color-active;
    }

    &--open {
      > svg:nth-child(1) {
        opacity: 1;
        transition: opacity 0.5 ease-in;
        color: $color-deposit-button;
      }

      > svg:nth-child(2) {
        transform: rotate(270deg);
        transition: transform 300ms ease-in-out;
      }
    }
  }

  &--active {
    border: 1px solid $color-active;
  }

  &--error {
    border: 1px solid $color-error;

    .bb-outcome-card__outcome__content {
      > small {
        color: $color-error;
      }
    }
  }
}
