@use '~scss/settings' as *;

.bb-personal-details {
  @extend %modal-grid;

  &__content {
    div:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  &__footer {
    @include flex(row, center, center);

    padding: 16px 0px;
    > button {
      width: 100%;
    }
  }

  > .bb-form-wrapper {
    .bb-calendar {
      margin-bottom: 24px;
      padding: 0px;
    }
  }
}
