@use '~scss/settings' as *;

.bb-empty-placeholder {
  @include flex(column, center, center);

  width: 100%;
  padding: 24px 8px;

  > p {
    text-align: center;
    margin: 0 0 8px;
  }

  &__title {
    @include font(14px, 20px, 700);

    color: $color-text-primary;
  }

  &__description {
    @include font(12px, 16px, 600);

    color: $color-text-secondary;
  }

  > svg {
    @include size(64px);
    color: $color-text-primary;
    margin: 0 0 12px;
  }
}
