@use '~scss/settings' as *;

.bb-team-stats {
  @include flex-gap(8px, column);
  width: 100%;

  &__data {
    @include flex(column, center, center);

    width: 100%;
    background-color: $color-cards-background;
    border-radius: $border-radius-standard;
    padding: 12px;

    > div:nth-child(2) {
      @include flex(row, flex-start, center);

      width: 100%;

      > p {
        margin: 0px;

        &:nth-child(1) {
          @include font(8px, 14px, 700);

          color: $color-text-secondary;
          text-transform: uppercase;
          margin-right: 12px;
        }

        &:nth-child(2) {
          @include font(10px, 14px, 700);

          color: $color-text-primary;
          text-transform: uppercase;
        }
      }
    }
  }

  &__fixture {
    @include flex(row, center, center);

    width: 100%;
    margin-bottom: 8px;

    > .bb-bet-team {
      width: 40%;
      height: auto;

      > img {
        @include size(48px);
        padding: 4px;
      }
    }
  }

  &__results {
    @include flex(column, center, center);

    margin: 0px 12px;
    min-width: 20%;

    > p {
      margin: 0px;
      text-transform: uppercase;

      &:nth-child(1) {
        @include font(10px, 14px, 500);

        color: $color-text-secondary;
        margin-bottom: 8px;
      }

      &:nth-child(2) {
        @include font(14px, 16px, 600);

        color: $color-text-primary;
      }
    }
  }
}
