@use '~scss/settings' as *;

.bb-bet-overview {
  z-index: 1;
  top: $modal-top;

  &__container {
    height: calc(100% - 112px);
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
  }

  &__header {
    @include flex(row, center, center);
    @include border-top-radius($border-radius-standard);

    position: relative;
    width: 100%;
    padding: 20px 16px;
    background-color: $color-background-primary;
    border-bottom: 1px solid $color-alabaster;

    > p {
      @include font(16px, 20px, 800);

      margin: 0px;
      color: $color-text-primary;
      letter-spacing: 0.5px;
      text-transform: capitalize;
    }

    > .ncoded-button {
      @include absolute(left 16px);

      min-width: 0px;
      background-color: transparent;
      color: $color-text-secondary;

      > svg {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    height: 100%;
    overflow: auto;
    padding: 0 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    .bb-bet {
      border-radius: 0px;
      @include border-bottom-radius($border-radius-standard);
      @include border-top-radius(0px);

      &__header {
        @include border-top-radius(0px);
      }

      margin-bottom: 8px;
    }
  }

  &__user-card {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: 1fr;
    grid-gap: 12px;

    width: 100%;
    background-color: $color-radio-slider;
    padding: 12px;

    &:first-child {
      @include border-top-radius($border-radius-standard);
    }

    &__content {
      @include flex(column, center, center);
      width: 100%;

      > div {
        @include flex(row, space-between, center);

        width: 100%;
      }

      > div:nth-child(1) {
        > p {
          @include font(14px, 20px, 700);

          margin: 0px;
          color: $color-text-primary;
          letter-spacing: 0.5px;
        }
      }

      > div:nth-child(2) {
        > p {
          @include font(10px, 14px, 700);

          margin: 0px;
          color: $color-text-secondary;
          letter-spacing: 0.5px;
          width: 100%;
          text-align: right;
        }
      }
    }

    &--active {
      background-color: $color-alabaster;
      border-radius: 12px;
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      > p {
        @include font(12px, 16px, 600);

        margin: 0px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }

      > button:nth-child(2) {
        @include absolute(left 0);

        min-width: 0px;
        margin-left: 16px;

        > svg {
          @include size(24px);
          transform: rotate(180deg);
        }
      }
    }

    > main {
      height: 100%;
    }
  }
}
