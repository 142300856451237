@use '~scss/settings' as *;

.bb-transaction-item {
  @include flex(column, center, center);

  width: 100%;

  .bb-collapsible {
    &__trigger {
      width: 100%;
    }
  }

  p {
    margin: 0px;
  }

  &__dot {
    border-radius: 50%;

    &--pending {
      background-color: $color-not-active-button;
    }

    &--win {
      background-color: $color-win-flag;
    }

    &--lose {
      background-color: $color-error;
    }
  }

  &__deposit {
    @include flex(column);
    @include flex-gap(16px, column);

    margin: 0 0 20px;

    .box {
      @include flex(column);

      &__title {
        @include font(12px, 16px, 600);

        letter-spacing: 0.5px;
        color: $color-text-secondary;
      }

      &__value {
        @include font(14px, 20px, 700);

        color: $color-text-primary;
        letter-spacing: 0.5px;
      }
    }
  }

  &__container {
    @include flex(row, space-between, center);

    width: 100%;
    padding: 12px 10px;
    transition: padding 300ms ease-out;

    &__data {
      @include flex(row, center, center);
      @include flex-gap(16px, row);

      > div:nth-child(1) {
        @include size(8px);
        border-radius: 50%;
      }

      > p:nth-child(2) {
        @include font(12px, 16px, 600);

        color: $color-text-secondary;
        letter-spacing: 0.5px;
      }

      > p:nth-child(3) {
        @include font(14px, 20px, 700);

        color: $color-text-primary;
        letter-spacing: 0.5px;
      }
    }

    > a {
      width: 100%;
      text-decoration: none;
    }

    > p {
      @include font(14px, 20px, 700);

      letter-spacing: 0.5px;
    }
  }

  &__date {
    @include flex(row, center, center);

    background-color: $color-alabaster;
    padding: 2px 12px;
    border-radius: 10px;

    > p {
      @include font(10px, 16px, 600);

      color: $color-rang;
    }
  }

  &--pending {
    color: $color-not-active-button;
  }

  &--lose {
    color: $color-error;
  }

  &--win {
    color: $color-win-flag;
  }

  &__preview {
    width: 100%;
    margin-bottom: 8px;
    max-height: 0;
    transition: max-height 300ms ease-out;
    border-radius: $border-radius-standard;
    overflow: hidden;

    &__container {
      @include flex(column, center, flex-start);

      padding: 12px;
      margin: 0 0 8px;
      background-color: $color-alabaster;

      > div {
        @include flex(column, center, flex-start);

        margin-bottom: 12px;

        > p {
          &:first-child {
            @include font(10px, 16px, 700);

            color: $color-text-secondary;
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }

          &:last-child {
            @include font(12px, 16px, 700);

            color: $color-text-primary;
            letter-spacing: 0.5px;
            word-break: break-word;
          }
        }
      }

      > .ncoded-button {
        width: 100%;
      }
    }

    &--active {
      max-height: 300px;
      transition: max-height 300ms ease-in;
    }
  }
}
