@use '~scss/settings' as *;

.bb-password-setup {
  @extend %modal-grid;

  &__content {
    position: relative;

    div:not(:first-of-type) {
      margin-top: 16px;
    }

    &__validation {
      @include flex(row, flex-start, center);

      margin-bottom: 12px;
      color: $color-error;

      &__text {
        @include font(12px, 16px, 600);

        margin: 0 0 0 12px;
        color: $color-text-secondary;
      }
    }

    .valid {
      color: $color-success;
    }

    &__match {
      @include flex(row, center, center);
      @include font(12px, 16px, 500);
      @include size(100%, 32px);

      position: absolute;
      bottom: 0;
      margin: 0;
      border-radius: $border-radius-standard;
      color: $color-reddish;
      background-color: $color-reddish-opacity;
    }
  }

  &__footer {
    @include flex(row, center, center);
    padding: 16px 0;

    .ncoded-button {
      width: 100%;
    }
  }
}
