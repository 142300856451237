@use '~scss/settings' as *;

.bb-wallet-modal {
  top: $modal-top;

  &__container {
    height: calc(100% - 112px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    background-color: $color-background-primary;
  }

  &__balance {
    @include flex(column, center, center);

    width: 100%;
    padding: 24px 12px;
    border-bottom: 1px solid $color-alabaster;

    > div:nth-child(1) {
      @include flex(column, flex-start, flex-start);

      gap: 12px;
      margin-bottom: 32px;
      width: 100%;

      > p:nth-child(1) {
        @include font(10px, 16px, 600);

        margin: 0px;
        color: $color-text-secondary;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      > p:nth-child(2) {
        @include font(48px, 48px, 700);

        margin: 0px;
        color: $color-text-primary;
      }
    }

    > div:nth-child(2) {
      @include flex(row, center, center);

      width: 100%;

      > button:nth-child(2) {
        margin-left: 12px;
        background-color: $color-text-flags;
        color: $color-button-text;
      }

      > button {
        width: 100%;
        height: 48px;
      }
    }
  }

  &__transactions {
    @include flex(column, flex-start, center);

    width: 100%;
    overflow: auto;
    padding: 22px 0px;

    &::-webkit-scrollbar {
      display: none;
    }

    > .loader-container {
      position: relative;
      margin-bottom: 12px;

      &--overlay {
        background-color: transparent;
      }
    }
  }

  &__prev-step-button {
    @include absolute(left 16px);

    min-width: 0px;

    > svg {
      @include size(24px);

      transform: rotate(180deg);
    }
  }

  .overlay__content {
    @extend %betbears-modal;

    > header {
      @include border-top-radius(16px);

      background-color: $color-background-primary;
      border-bottom: 1px solid $color-alabaster;

      > p {
        @include font(16px, 20px, 800);

        margin: 0px;
        letter-spacing: 1px;
      }
    }

    > main {
      height: 100%;

      > .bb-appear-animation {
        height: 100%;
        > form {
          height: calc(100% - 112px);
        }
      }
    }
  }
}
