@use '~scss/settings' as *;

.ncoded-button.bb-referral-code-option {
  @include size(100%, 48px);

  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  color: $color-text-primary;
  background-color: $color-player-card-background;

  > svg {
    @include size(20px);
    fill: $color-text-secondary;
  }
}
