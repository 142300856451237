@use '~scss/settings' as *;

.bb-share-with-friend {
  @extend %modal-grid;

  &__select {
    @extend %slider-secondary;
    margin-top: 16px;
    margin-bottom: 0px;
    padding: 14px 0px;
  }

  &__container {
    @include flex(column, flex-start, flex-start);

    width: 100%;
    padding: 0px 16px;

    > div {
      width: 100%;
    }
  }

  &__session {
    @include flex(row, center, center);

    width: 100%;

    > div:nth-child(1) {
      @include flex(row, flex-start, center);

      padding: 0px 4px;
      width: 100%;
      height: 64px;

      > img {
        @include size(40px);

        object-fit: cover;
        border-radius: 50%;
      }

      > p {
        color: $color-text-primary;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin: 0px;
        margin-left: 12px;
        font-weight: 700;
      }
    }

    > div:nth-child(2) {
      .ncoded-button {
        @include size(24px);

        min-width: 24px;
        min-height: 24px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $color-disabled;

        > svg {
          display: none;
        }
      }
    }

    &--active {
      > div:nth-child(2) {
        .ncoded-button {
          > svg {
            display: flex;
            color: $color-active;
            margin: 0px;
          }
        }
      }
    }
  }

  &__footer {
    @extend %modal-edit-bet-footer;
  }
}
