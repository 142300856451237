@use '~scss/settings' as *;

.bb-security {
  @extend %modal-grid;

  &__container {
    @include flex(column, flex-start, center);
    @include size(100%);

    > p:first-of-type {
      font-size: 14px;
      color: $color-rang;
      font-weight: 600;
    }

    > button {
      width: 100%;
    }

    > p:nth-child(3) {
      color: $color-error;
      font-size: 14px;
      text-align: center;
    }
  }

  &__footer {
    @include flex(row, center, center);

    padding: 16px 0px;
    > button {
      width: 100%;
    }
  }
}
