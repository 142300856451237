@use '~scss/settings' as *;

.betbears-createaccount-modal {
  height: auto;

  .overlay__content {
    @extend %betbears-modal;
    @include flex(column);

    > header {
      position: relative;
      flex: 0 0 auto;

      > p {
        font-weight: 600;
        font-size: 12px;
      }

      > .ncoded-button:nth-child(1) {
        min-width: 0px;
        position: absolute;
        left: 0;
        margin-left: 16px;

        > svg {
          transform: rotate(180deg);
        }
      }
    }

    > main {
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 1fr;

      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .betbears-progress {
        margin-bottom: 10px;
      }

      .input-container {
        width: 100%;
      }

      .ncoded-button {
        height: 44px;

        > svg {
          color: $color-text-secondary;
        }
      }
    }
  }

  &__details {
    display: flex;
    overflow: hidden;
    width: 100%;
    color: $color-gray-e7;
    margin: 26px 0px;

    > div {
      @include size(100%, 40px);
      @include flex(row, space-between, flex-start);

      > p:first-child {
        margin: 0px;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        color: $color-text-primary;
      }

      > p:nth-child(2) {
        margin: 0px;
        color: $color-rang;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }
  }

  &__content {
    @include flex(column, center, center);
    @include size(100%);

    .button {
      width: 100%;
    }
  }

  .bb-createaccount-modal-container {
    @include flex(column, flex-start, center);
    @include size(100%);

    padding: 0px 16px;

    form {
      @include size(100%);

      > button {
        width: 100%;

        &[type='submit'] {
          flex: 0 0 auto;
          margin-bottom: 24px;
        }
      }
    }
  }
}

.form-step-container {
  @include flex(column, flex-start, center);

  position: relative;
  height: 100%;

  &,
  .form-step {
    @include size(100%);
  }

  .form-step {
    &-enter {
      @include absolute(top 0 left 0);
    }

    &-exit-active {
      .bb-appear-animation--move-in {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        animation-delay: 0ms;
        animation-name: moveOut, disappear;
      }
    }
  }
}
