@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;
@use './tools/t-flex' as *;
@use './tools/t-font-size' as *;

%standard-setting {
  @include flex(row, space-between, center);
  @include size(100%, 48px);

  background-color: $color-player-card-background;
  border-radius: 8px;
  padding: 0px 10px;

  > p {
    @include font(14px, 16px, 600);

    width: 100%;
    margin: 0px;
    text-align: left;
    color: $color-text-primary;
  }

  > svg {
    color: $color-text-secondary;
    transform: rotate(90deg);
  }
}
