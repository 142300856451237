@use '~scss/settings' as *;

.bb-publish-settings {
  @extend %modal-grid;

  &__container {
    @include flex(row, center, flex-start);

    position: relative;
    padding: 0px 16px;
    margin-top: 16px;

    > div:last-child {
      @include absolute(bottom 12px);

      width: 100%;
      padding: 0px 16px;

      > .ncoded-button {
        width: 100%;
        min-height: 44px;
        padding: inherit;
        color: $color-text-secondary;
        background-color: $color-not-active-button;
        font-weight: 700;
      }
    }
  }

  &__footer {
    @extend %modal-edit-bet-footer;
  }
}
