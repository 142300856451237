@use '~scss/settings' as *;

.bb-toggle {
  @include flex(row, flex-start, center);

  --circle-diametar: 12px;

  min-width: 52px;
  min-height: 20px;

  &__circle {
    @include size(var(--circle-diametar));

    background-color: $color-white;
    border-radius: 50%;
  }

  &__slide {
    @include flex(row, flex-start, center);
    @include size(100%);

    padding: 4px;
    position: relative;
    cursor: pointer;

    background-color: $color-disabled;
    opacity: 0.5;
    border-radius: $border-radius-round;

    &--active {
      background-color: $color-active;
      opacity: 1;
    }
  }

  > input {
    display: none;
  }

  input[type='checkbox']:checked + .bb-toggle__circle {
    transform: translate3d(273%, 0, 0);
    transition: transform 0.2s ease-in;
  }

  input[type='checkbox']:not(:checked) + .bb-toggle__circle {
    transform: translate3d(0%, 0, 0);
    transition: transform 0.2s ease-out;
  }
}
