@use '~scss/settings' as *;

.bb-friends {
  @include flex(column, flex-start, center);

  width: 100%;
  overflow: auto;
  margin-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    @include size(100%);
    @include flex-gap(12px, column);

    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
    padding: 0px 8px;

    > .bb-infinite-scroll {
      @include border-top-radius($border-radius-standard);
      @include size(100%);

      background-color: $color-cards-background;
      padding: 12px;
      overflow: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__radio-slider {
    .bb-animated-radio__content {
      flex: 1 1 100%;
    }
  }

  &__header {
    @extend %menu-header;
  }

  &__select {
    @extend %slider-secondary;
  }

  .ncoded-input {
    @extend %input-rounded;
    @include size(100%, 36px);

    border: 0px;
    padding: 0px 8px;
    background-color: $color-search;

    &:focus-within {
      border: 0px;
    }

    > input::placeholder {
      color: $color-disabled;
    }
  }
}
