@use '~scss/settings' as *;
.bb-collapsible {
  @include flex(column, center, flex-end);
  @include size(100%);

  header {
    border: none;
  }

  position: relative;

  &__trigger {
    cursor: pointer;
  }

  &__content {
    overflow: auto;
  }

  &__animated {
    width: 100%;
  }
}
