@use '~scss/settings' as *;

.bb-favorite-teams {
  @include flex(column, flex-start, center);

  width: 100%;
  overflow: auto;
  padding: 0 0 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    @include flex-gap(8px, column);
    @include size(100%);

    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
    padding: 0px 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    > .bb-infinite-scroll {
      @include flex-gap(8px, column);

      width: 100%;

      > .ncoded-button {
        @include font(14px, 20px, 600);

        width: 100%;
        margin-top: 32px;
        letter-spacing: 0.5px;
        background-color: $color-not-active-button;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__header {
    @extend %menu-header;

    margin-top: 12px;

    > .ncoded-button:nth-child(3) {
      @include absolute(top 50% right 16px);

      @extend %action-round-button;
      transform: translate3d(0, -50%, 0);

      > svg {
        margin: 0;
      }
    }
  }
}
