@use '~scss/settings' as *;

.bb-public-bets {
  @include flex(column, flex-start, center);
  @include size(100%);

  overflow: hidden;

  &__content {
    @include flex-gap(8px, column);

    overflow: auto;
    width: 100%;
    padding: 0 0 8px;
  }
}
