@use './variables' as *;
@use './color' as *;
@use './tools/t-size' as *;

%primary-button {
  border-radius: $border-radius-standard;
  background-color: $color-deposit-button;
  color: $color-text-primary;
  height: $button-height;
  font-weight: 600;
}

%secondary-button {
  border-radius: $border-radius-standard;
  background-color: $color-background-primary;
  color: $color-text-secondary;
  height: $button-height;
  font-weight: 600;
}

%secondary-border-button {
  @extend %secondary-button;
  border: 1px solid $color-disabled;
}

%action-round-button {
  @include size(36px);

  padding: 6px;
  color: $color-white;
  min-width: 36px;
  background-color: $color-active;
  border-radius: $border-radius-round;
  box-shadow: $neon-box-shadow;
}

@mixin button-style {
  .ncoded-button {
    padding: unset;

    &--primary {
      @extend %primary-button;

      &:disabled {
        background-color: $color-text-secondary;
        color: $color-white;
      }

      &.ncoded-button--solid.ncoded-button--solid {
        color: $color-button-text;

        font-weight: bold;
      }

      &--unique {
        background-color: $color-unique-button;
      }
    }

    &--secondary {
      @extend %secondary-button;
    }

    &--primary-round {
      @extend %primary-button;
      border-radius: $border-radius-round;
    }

    &--secondary-round {
      @extend %secondary-button;
      border-radius: $border-radius-round;
      border: none;
    }
  }
}
